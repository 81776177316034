import { Component, OnInit } from '@angular/core';
import { WarrantySummaryReferenceSiteService } from '../../../services/warrantySummaryReferenceSite.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'reference-site-list',
    templateUrl: './referenceSiteList.component.html',
    styleUrls: ['./referenceSiteList.component.scss']
})
export class ReferenceSiteListComponent implements OnInit {
    referenceSiteList: any[] = [];
    constructor(
        public warrantySummaryReferenceSiteService: WarrantySummaryReferenceSiteService
    ) { }

    ngOnInit() {
        this.warrantySummaryReferenceSiteService
            .getWarrantySummaryReferenceSite()
            .subscribe(
                data => {
                    if (data) {
                        this.referenceSiteList = data.allDomainAndStatus;
                    }
                },
                (err: HttpErrorResponse) => {
                    console.log(err.message);
                }
            );
    }
}
