import {
    Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild,
    ElementRef
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { WarrantySettingsService } from '../../../services/warrantySettings.service';
declare var stripe: any;
declare var elements: any;

@Component({
    selector: 'app-warranty-setting-detail',
    templateUrl: './warranty-setting-detail.component.html',
    styleUrls: ['./warranty-setting-detail.component.scss']
})
export class WarrantySettingDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('cardInfo') cardInfo: ElementRef;
    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    form: FormGroup;
    message;
    messageClass;
    processing = false;
    showHidePaymentLoader: boolean;
    showHidePaymentSuccessPage: boolean;
    paymentSuccessOption: {} = {};
    warrantySettingData: any = {
        bankAccNo: '',
        bankName: {},
        bankRoutingNo: '',
        buyerName: '',
        buyerEmail: '',
        buyerPhone: '',
        beneficiaryCompanyName: '',
        buyerAddr: '',
        buyerState: '',
        buyerZip: '',
        buyerCity: '',
        buyerCountry: ''
    };
    constructor(
        private formBuilder: FormBuilder,
        private _location: Location,
        private warrantySettingsService: WarrantySettingsService,
        private cd: ChangeDetectorRef
    ) {
        this.createForm();
        this.showHidePaymentLoader = false;
        this.showHidePaymentSuccessPage = false;
    }
    ngOnInit() {
        this.warrantySettingsService.GetWarrantySettingsData().subscribe(data => {
            this.warrantySettingData = data;
            this.form.controls['bankName'].setValue(this.warrantySettingData.bankName);
            this.form.controls['bankAccNo'].setValue(this.warrantySettingData.bankAccNo);
            this.form.controls['bankRoutingNo'].setValue(this.warrantySettingData.bankRoutingNo);
            this.form.controls['name'].setValue(this.warrantySettingData.buyerName);
            this.form.controls['email'].setValue(this.warrantySettingData.buyerEmail);
            this.form.controls['phone'].setValue(this.warrantySettingData.buyerPhone);
            this.form.controls['primaryContactPersonName'].setValue(this.warrantySettingData.buyerName);
            this.form.controls['primaryContactBussinessName'].setValue(this.warrantySettingData.beneficiaryCompanyName);
            this.form.controls['primaryContactEmail'].setValue(this.warrantySettingData.buyerEmail);
            this.form.controls['primaryContactPhone'].setValue(this.warrantySettingData.buyerPhone);
            this.form.controls['primaryContactAddress'].setValue(this.warrantySettingData.buyerAddr);
            this.form.controls['primaryContactState'].setValue(this.warrantySettingData.buyerState);
            this.form.controls['primaryContactCity'].setValue(this.warrantySettingData.buyerCity);
            this.form.controls['primaryContactZip'].setValue(this.warrantySettingData.buyerZip);
            this.form.controls['primaryContactCountry'].setValue(this.warrantySettingData.buyerCountry);
        });
    }
    ngAfterViewInit() {
        const style = {
            base: {
                lineHeight: '24px',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#898989'
                }
            }
        };
        this.card = elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);

        this.card.addEventListener('change', this.cardHandler);
    }
    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }
    onChange({ error }) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }
    createForm() {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            name: ['', Validators.compose([Validators.required])],
            bankName: ['', Validators.compose([Validators.required])],
            bankAccNo: ['', Validators.compose([Validators.required])],
            bankRoutingNo: ['', Validators.compose([Validators.required])],
            phone: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ // checks for valid phone no
                    )
                ])
            ],
            primaryContactPersonName: ['', Validators.compose([Validators.required])],
            primaryContactBussinessName: [
                '',
                Validators.compose([Validators.required])
            ],
            primaryContactEmail: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(30),
                    Validators.email
                ])
            ],
            primaryContactPhone: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ // checks for valid phone no
                    )
                ])
            ],
            primaryContactAddress: ['', Validators.compose([Validators.required])],
            primaryContactState: ['', Validators.compose([Validators.required])],
            primaryContactCity: ['', Validators.compose([Validators.required])],
            primaryContactZip: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(/^\d{5}(-\d{4})?$/) // chacks for valid zip code
                ])
            ],
            primaryContactCountry: ['', Validators.compose([Validators.required])],
            checkParam: new FormControl({ value: false, disabled: this.isDisabled() })
        });
    }

    disableForm() {
        this.form.controls['email'].disable();
        this.form.controls['name'].disable();
        this.form.controls['phone'].disable();
        this.form.controls['primaryContactPersonName'].disable();
        this.form.controls['primaryContactBussinessName'].disable();
        this.form.controls['primaryContactEmail'].disable();
        this.form.controls['primaryContactPhone'].disable();
        this.form.controls['primaryContactAddress'].disable();
        this.form.controls['primaryContactState'].disable();
        this.form.controls['primaryContactCity'].disable();
        this.form.controls['primaryContactZip'].disable();
        this.form.controls['primaryContactCountry'].disable();
    }

    isDisabled() {
        return false;
    }

    enableForm() {
        this.form.controls['email'].enable();
        this.form.controls['name'].enable();
        this.form.controls['phone'].enable();
        this.form.controls['primaryContactPersonName'].enable();
        this.form.controls['primaryContactBussinessName'].enable();
        this.form.controls['primaryContactEmail'].enable();
        this.form.controls['primaryContactPhone'].enable();
        this.form.controls['primaryContactAddress'].enable();
        this.form.controls['primaryContactState'].enable();
        this.form.controls['primaryContactCity'].enable();
        this.form.controls['primaryContactZip'].enable();
        this.form.controls['primaryContactCountry'].enable();
    }
    async onSubmit() {
        this.processing = true;
        this.disableForm();
        this.showHidePaymentLoader = true;
        const { token, error } = await stripe.createToken(this.card);
        if (error) {
            console.log('Something is wrong:', error);
        } else {
            this.processing = true;
            this.disableForm();
            this.showHidePaymentLoader = true;
            console.log('Success!', token);
        }
    }

    previousHandle() {
        this._location.back();
    }

    cancelHandle() {
        console.log('cancel clicked...');
    }

    copyBuyerDetail() {
        if (this.form.get('checkParam').value) {
            this.form.controls['primaryContactPersonName'].setValue(
                this.form.get('name').value
            );
            this.form.controls['primaryContactEmail'].setValue(
                this.form.get('email').value
            );
            this.form.controls['primaryContactPhone'].setValue(
                this.form.get('phone').value
            );
        } else {
            this.form.controls['primaryContactPersonName'].reset();
            this.form.controls['primaryContactEmail'].reset();
            this.form.controls['primaryContactPhone'].reset();
        }
    }
}
