import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(private _location: Location) { }

    previousHandle() {
        this._location.back();
    }

    secondsToHms(d) {
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);

        const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' min ') : '';
        const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''; // if needed
        return hDisplay + mDisplay;
    }

    historyDateConverter(date) {
        let formatted_date = `${moment().format('MMM D, YYYY')}`;
        if (date) {
            formatted_date = `${moment(date).format('MMM D, YYYY')}`;
        }
        return formatted_date;
    }
    getLocaleDate(date) {
      return moment('January 15 2012').locale('fr').format('MMM D, YYYY');
    //  return moment(date).local().format('MMM D, YYYY');

    }
    checkPasswords(group: FormGroup) {
        const pass = group.controls.newPassword.value;
        const confirmPass = group.controls.confirmPassword.value;
        if (confirmPass.length <= 0) {
            return false;
        }
        return pass === confirmPass ? false : true;
    }


    // google map functions here
    getAddrComponent(place, componentTemplate) {
        let result;
        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];
            if (componentTemplate[addressType]) {
                result = place.address_components[i][componentTemplate[addressType]];
                // console.log(' place, result addr component', place, result);
                return result;
            }
        }
        return;
    }
    getAddress(place: object) {
        return place['formatted_address'];
    }
    getStreetNumber(place) {
        const COMPONENT_TEMPLATE = { street_number: 'short_name' },
            streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return streetNumber;
    }
    getStreet(place) {
        const COMPONENT_TEMPLATE = { route: 'long_name' },
            street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return street;
    }
    getStreetAndStreetNumber(place) {
        let streetNumber = '';
        let streetName = '';
        if (this.getStreetNumber(place) && typeof this.getStreetNumber(place) !== 'undefined') {
            streetNumber = this.getStreetNumber(place);
        }
        if (this.getStreet(place) && typeof this.getStreet(place) !== 'undefined') {
            streetName = this.getStreet(place);
        }
        return streetNumber + ' ' +streetName;
    }
    getCity(place) {
        const COMPONENT_TEMPLATE = { locality: 'long_name' },
            city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return city;
    }
    getState(place) {
        const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
            state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return state;
    }
    getDistrict(place) {
        const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
            state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return state;
    }
    getCountryShort(place) {
        const COMPONENT_TEMPLATE = { country: 'short_name' },
            countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return countryShort;
    }
    getCountry(place) {
        const COMPONENT_TEMPLATE = { country: 'long_name' },
            country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return country;
    }
    getPostCode(place) {
        //   console.log('place in google component postal code', place);
        const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
            postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return postCode;
    }
    getPhone(place) {
        const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' },
            phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return phone;
    }
    //   google map functions end here


}

