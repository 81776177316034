import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class WeatherLocationFormService {
    private locationInfo = new BehaviorSubject('');

    private apiData = new BehaviorSubject('');
    
    locInfo$ = this.locationInfo.asObservable();

    apiInfo$ = this.apiData.asObservable();
    
    constructor(private http: HttpClient, private environment: AppConfigService) { }
    
    // public getFakeData(): Observable<any> {
    //     let headers = new HttpHeaders();
    //     headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //     return this.http
    //         .get('./assets/jsonData/analysedWebsite.json')
    //         .pipe(delay(2000));
    // }

    public getQuotes(address):Observable<any>{
        let headers = new HttpHeaders();
        
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        headers = headers.set('Authorization', this.environment.config.authToken);

        return this.http
            .get(this.environment.config.apiUrl,{
                params: {
                    addr: address
                },
                headers: headers
            });     
    }

    public getAllAddressList() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get('../assets/jsonData/weatherLocationData.json');
    }
    
    getBuldingLocationInfo() {
        return this.setBuldingLocationInfo;
    }
    
    setBuldingLocationInfo(formData: any) {
        this.locationInfo.next(formData);
    }

    public setApiData(data){
        this.apiData.next(data);
    }

    public getApiData()
    {
        return this.setApiData; 
    }

}
