import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';
import { ManageProfileService } from '../../services/manageProfile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PassUserInfoService } from '../../services/passUserInfo.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    passState: boolean;
    showPasswordValidationtext: boolean;
    passInputType = 'password';
    user: any = {};
    alertImpLogo: string;
    accountNotExist: boolean;
    incorrectPassword: boolean;
    env: any;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private environment: AppConfigService,
        private manageProfile: ManageProfileService,
        private passUserInfoService: PassUserInfoService
    ) {
        this.env = environment.config;
        this.passState = true;
        this.showPasswordValidationtext = false;
        this.manageProfile.getUserDetails().subscribe(
            data => {
                this.user = data;
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
        this.resetErrorState();
    }

    resetErrorState() {
        this.accountNotExist = false;
        this.incorrectPassword = false;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            emailId: ['', [Validators.required, Validators.email,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
            password: [
                '',
                [
                    Validators.required,
                    // Validators.minLength(8),
                    // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')
                    // pattern for checking password validation
                ]
            ]
        });
    }
    get f() {
        return this.loginForm.controls;
    }

    get emailId() {
        return this.loginForm.get('emailId');
    }

    get password() {
        return this.loginForm.get('password');
    }

    onSubmit() {
        this.submitted = true;
        if (this.user.emailId !== this.loginForm.get('emailId').value) {
            this.accountNotExist = true;
            this.incorrectPassword = false;
            return;
        } else if (this.user.password !== this.loginForm.get('password').value) {
            this.accountNotExist = false;
            this.incorrectPassword = true;
            return;
        } else if (this.loginForm.invalid) {
            return;
        } else {
            this.passUserInfoService.setUserInfo(this.user);
            this.resetErrorState();
            this.router.navigate(['/manage-service']);
        }
    }
    showOrHidePass() {
        if (this.passState) {
            this.passInputType = 'text';
            this.passState = false;
        } else if (this.passState === false) {
            this.passInputType = 'password';
            this.passState = true;
        }
    }
}
