const flowData = sessionStorage.getItem('flow');
let allroute = {
    domain: 'domain',
    coverage: 'coverage',
    // warrantySummary: 'warrantySummary',
    verify: 'verify',
    checkout: 'checkout',
    failure: 'checkout/failure'
};
// route for weather endorsement based on flowdata
if (flowData === 'WeatherEndPurchase') {
    allroute = {
        domain: 'location',
        coverage: 'payout',
        verify: 'verify',
        checkout: 'checkout',
        failure: 'checkout/failure'
    };
}
export let STEPS = allroute;

