import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import {
    PageScrollInstance,
    PageScrollService,
    EasingLogic
} from 'ngx-page-scroll';


import { ManageProductFlow } from '../../../services/manageProductFlow.service';
import { AllRiskDetailService } from '../../../services/allRiskDetails.service';

@Component({
    selector: 'app-warranties-intro',
    templateUrl: './allWarrantiesShortIntro.component.html',
    styleUrls: ['./allWarrantiesShortIntro.component.scss']
})
export class AllWarrantiesIntroComponent implements OnInit {
    landingPageCover: string;
    allRisks: any;
    warranty: any;
    keys(obj) {
        return Object.keys(obj);
    }
    constructor(
        private router: Router,
        public allRiskDetailService: AllRiskDetailService,
        private manageProductFlow: ManageProductFlow
    ) {
        this.allRisks = {};
    }
    ngOnInit() {
        this.allRiskDetailService.allRiskDetailsList().subscribe(
            data => {
                if (data) {
                    this.allRisks = data;
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            }
        );
    }
    routeToWarrantyDetails(item) {
        if (item.riskName === 'WID') {
            sessionStorage.setItem('flow', 'WarrantyPurchase');
        } else if (item.riskName === 'STORM LOCK') {
            sessionStorage.setItem('flow', 'WeatherEndPurchase');
        }
        //  if (item.riskName === 'WID WARRANTY') {
        //     this.manageProductFlow.setUserFlow('WarrantyPurchase');
        // } else if (item.riskName === 'WEATHER ENDORSEMENT') {
        //     this.manageProductFlow.setUserFlow('WeatherEndPurchase');
        // }
        // this.router.navigate([item.routingLink]);
    }
}
