import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ManageDetailsService {
    // private selectedService = new Subject<any>();
    private selectedService = new BehaviorSubject('service');
    selService$ = this.selectedService.asObservable();
    private payoutPendingStatus = new BehaviorSubject(false);
    payoutPending = this.payoutPendingStatus.asObservable();
    private changeTabState = new Subject<number>();
    changeTab = this.changeTabState.asObservable();
    constructor(private http: HttpClient) { }

    public getServices(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get('./assets/jsonData/manageServices.json');

    }
    setService(state: any) {
        this.selectedService.next(state);
    }
    payoutPendingStatusInfo(status: boolean) {
        this.payoutPendingStatus.next(status);
    }
    setNewTab(tabId) {
        this.changeTabState.next(tabId);
    }
}
