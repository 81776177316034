import { Component, OnInit, NgModule, Input } from '@angular/core';
import { StepperStepsCompletedService } from '../../../services/stepperStepsCompleted.service';
import { WarrantyPaymentSuccessService } from '../../../services/warrantyPaymentSuccess.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../../app-config.service';
import { PassUserInfoService } from '../../../services/passUserInfo.service';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';
import { WeatherLocationFormService } from '../../../services/weatherLocationForm.service';
import { Subscription } from 'rxjs';
import jsPDF from 'jspdf';
@Component({
    selector: 'app-warranty-info-purchase',
    templateUrl: './warrantyInfoPurchase.component.html',
    styleUrls: ['./warrantyInfoPurchase.component.scss']
})
export class WarrantyInfoPurchaseComponent implements OnInit {
    userInfo: any;
    domainInfo: any;
    payoutInfo: any;
    flowData: string;
    private sub1: Subscription;
    private sub2: Subscription;
    private sub3: Subscription;
    urlType: any;
    env: any;
    @Input() paymentSuccessOption: {
        warrantyName: string;
        warrantyWebpage: string;
        warrantySuccessOption: string;
    };
    isCollapsedUserInfo: boolean;
    userAdditionalInfoForm = new FormGroup({
        warrantySummaryUndertaking: new FormControl('')
    });
    userInfoInputNumbers = new FormGroup({
        EINSNumber: new FormControl(''),
    });
    addr: any;
    address: string;
    fee: any;
    constructor(private stepperStepsCompletedService: StepperStepsCompletedService,
        private warrantyPaymentSuccess: WarrantyPaymentSuccessService,
        private passUserInfoService: PassUserInfoService,
        private passWIDInfoService: PassWIDInfoService,
        private weatherLocationFormService: WeatherLocationFormService,
        private environment: AppConfigService) {
        this.flowData = sessionStorage.getItem('flow');
        if (this.flowData === 'WeatherEndPurchase') {
            this.sub1 = this.weatherLocationFormService.locInfo$.subscribe(
                address => {
                    this.addr = address;
                    this.address = this.addr.streetName + ' ' + this.addr.cityName
                        + ', ' + this.addr.stateName + ', ' + this.addr.zip;

                });
        }
        this.isCollapsedUserInfo = true;
        this.env = environment.config;
        this.userInfo = this.passUserInfoService.getUserInfo();
        this.sub1 = this.passWIDInfoService.selDomain$.subscribe(
            domain => {
                this.domainInfo = domain;
            });
        this.sub2 = this.passWIDInfoService.selPayInfo$.subscribe(
            payinfo => {
                this.payoutInfo = payinfo;
            });
        this.sub3 = this.passWIDInfoService.selUrlType$.subscribe(
            ut => {
                this.urlType = ut;
            });
        
    }
    downloadPdf() {
        const doc = new jsPDF();

        doc.text('contract Summary', 10, 10);
        doc.save('contract_Summary.pdf');
    }
    ngOnInit() {
        // this.warrantyPaymentSuccess.WarrantyPaymentSuccessFunctionCall().subscribe(
        //   data => {
        //     if(data){
        //       this.paymentSuccessOption = data.warrantySuccessContent;
        //       // console.log( data);
        //
        //   },
        //   (err: HttpErrorResponse) => {
        //     console.log (err.message);
        //   });
        // this.stepperStatusChanged();
    }
    // stepperStatusChanged() {
    //   this.stepperStepsCompletedService.changeThirdStepperStatus(true)
    // }
    downladWarrantyDocument() {
        console.log('document will be downloaded');
    }
}
