import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WarrantySettingsService } from '../../../services/warrantySettings.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-warranty-setting',
    templateUrl: './warranty-setting.component.html',
    styleUrls: ['./warranty-setting.component.scss']
})
export class WarrantySettingComponent implements OnInit {
    onOffText: string;
    enable = true;
    @Output() autoRenewalClickEvent = new EventEmitter<boolean>();
    constructor(private warrantySettingsService: WarrantySettingsService, private router: Router) { }
    warrantySettingData: any = {
        bankAccNo: '',
        bankName: {},
        bankRoutingNo: '',
        buyerName: '',
        buyerEmail: '',
        buyerPhone: '',
        beneficiaryCompanyName: '',
        buyerAddr: '',
        buyerState: '',
        buyerZip: '',
        buyerCity: '',
        buyerCountry: '',
        creditCardNumber: ''
    };
    ngOnInit() {
        this.onOffTextChange();
        this.getWarrantySettings();
    }
    getWarrantySettings() {
        this.warrantySettingsService.GetWarrantySettingsData().subscribe(data => {
            this.warrantySettingData = data;
        });
    }
    onChange(value: boolean) {
        this.enable = value;
        this.autoRenewalClickEvent.emit(value);
        this.onOffTextChange();
    }
    onOffTextChange() {
        this.onOffText = this.enable ? 'on' : 'off';
        console.log('onOff warranty api called');
    }
    changeDetails() {
        this.router.navigate(['/change-details']);
    }
}
