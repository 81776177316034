import { Injectable } from '@angular/core';

@Injectable()
export class WMPurchaseService {
    private userInfo: any;
    private purchaseInfo: any;
    constructor() {
        this.userInfo = {};
        this.purchaseInfo = {};

    }

    setWMPurchaseInfo(details) {
        this.purchaseInfo = details;
    }
    setUserInfo(user) {
        this.userInfo = user;
    }
    getUserInfo() {
        return this.userInfo;
    }
    getWMPurchaseInfo() {
        return this.purchaseInfo;
    }
}
