import { Component, OnInit } from '@angular/core';
import { MarketWarranty } from '../../services/marketWarranty.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../app-config.service';
@Component({
    selector: 'app-marketing',
    templateUrl: './marketing.component.html',
    styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
    env: any;
    domainPath: string;
    constructor(private marketWarranty: MarketWarranty, private environment: AppConfigService) {
        this.env = environment.config;
        this.domainPath = '/warranty/domain';
    }
    warrantyInfo: any;
    steps: string[] = [];
    problemTitle: string;
    probReason: string[] = [];
    businessImpact: string[] = [];
    solutionTitle: string;
    solReason: string[] = [];
    workStructureTitle: string;
    workStructure: string[] = [];
    workStructureRes: string[] = [];
    title: string;
    slogan: string;
    landingPageCover: string;
    ngOnInit() {
        sessionStorage.setItem('flow', 'WarrantyPurchase');
        this.marketWarranty.getWarrantyInfo().subscribe(
            data => {
                this.warrantyInfo = data.warrantyInfo[0];
                this.steps = data.warrantyInfo[0].content.steps;
                this.problemTitle = data.warrantyInfo[0].content.problem.title;
                this.probReason = data.warrantyInfo[0].content.problem.reason;
                this.businessImpact = data.warrantyInfo[0].content.businessImpact;
                this.solutionTitle = data.warrantyInfo[0].content.solution.title;
                this.solReason = data.warrantyInfo[0].content.solution.reason;
                this.workStructureTitle = data.warrantyInfo[0].content.workStructure.title;
                this.workStructureRes = data.warrantyInfo[0].content.workStructure.reason;
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
    }

}
