import {
    Component, OnInit, NgModule, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild,
    ElementRef, TemplateRef
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ManageProfileService } from '../../services/manageProfile.service';
import { AppConfigService } from '../../app-config.service';
import { PasswordCheckService } from '../../services/passwordCheck.service';
import { UtilityService } from '../../services/utility.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var stripe: any;
declare var elements: any;

@Component({
    selector: 'app-manage-profile',
    templateUrl: './manageProfile.component.html',
    styleUrls: ['./manageProfile.component.scss']
})

export class ManageProfileComponent implements OnInit, AfterViewInit, OnDestroy {
    profileForm: FormGroup;
    verifyContactForm: FormGroup;
    ChangePwdForm: FormGroup;
    userProfile: any;
    allCountryList: any[] = [];
    modalRef: BsModalRef;
    @ViewChild('cardInfo') cardInfo: ElementRef;
    card: any;
    error: string;
    cardHandler = this.onChange.bind(this);
    disabledField: boolean;
    // contactInfo: any;
    crossBtn: string;
    oldPassword: string;
    showPasswordValidationtext: boolean;
    passState: boolean;
    newPassState: boolean;
    passInputType = 'password';
    newPassInputType = 'password';
    passwordNotSame: boolean;
    selectedCountry: any;
    // verifiedNumber: boolean;
    constructor(private router: Router, private manageProfile: ManageProfileService, private modalService: BsModalService,
        private formBuilder: FormBuilder, private cd: ChangeDetectorRef, public environment: AppConfigService,
        private util: UtilityService) {
        this.createForm();
        // this.verifyContactForm = this.formBuilder.group({
        //     otpForMobileNo: ['', [Validators.required, Validators.maxLength(6),
        //     Validators.minLength(6)]]
        // });
        this.createPwdForm();
        this.manageProfile.getProfileInfo().subscribe(
            data => {
                this.userProfile = data;
                this.profileForm.get('firstName').setValue(this.userProfile.firstName);
                this.profileForm.get('lastName').setValue(this.userProfile.lastName);
                this.profileForm.get('registeredEmail').setValue(this.userProfile.registeredEmail);
                this.profileForm.get('registeredEmail').disable();
                // this.profileForm.get('password').setValue(this.userProfile.password);
                this.profileForm.get('password').disable();
                // this.profileForm.get('higherPay').setValue(true);
                this.profileForm.get('billingCountryName').setValue(this.userProfile.country);
                this.profileForm.get('billingAddressName').setValue(this.userProfile.address);
                this.profileForm.get('billingCityName').setValue(this.userProfile.city);
                this.profileForm.get('billingStateName').setValue(this.userProfile.state);
                this.profileForm.get('billingZip').setValue(this.userProfile.zip);
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
        this.showPasswordValidationtext = false;
    }

    ngOnInit() {
        this.allCountryList = ['USA', 'India'];
        this.profileForm.disable();
        this.disabledField = true;
        this.passState = true;
        this.newPassState = true;
        // this.verifiedNumber = false;
    }
    createForm() {
        this.profileForm = this.formBuilder.group({
            // registeredEmail: ['', Validators.compose([Validators.required, Validators.email,
            // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
            registeredEmail: [''],
            firstName: ['', Validators.compose([Validators.required, Validators.pattern('(?!^ +$)^.+$')])],
            lastName: ['', Validators.compose([Validators.required, Validators.pattern('(?!^ +$)^.+$')])],
            // contact: [
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.pattern(
            //             /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ // checks for valid phone no
            //         )
            //     ])
            // ],
            password: ['Pass@123'],
            // higherPay: ['', Validators.compose([Validators.required])],
            billingCountryName: ['', Validators.compose([Validators.required])],
            billingAddressName: ['', Validators.compose([Validators.required])],
            billingCityName: ['', Validators.compose([Validators.required])],
            billingStateName: ['', Validators.compose([Validators.required])],
            billingZip: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(5),
                    Validators.minLength(5),
                    Validators.pattern(/^\d{5}(-\d{4})?$/) // chacks for valid zip code
                ])
            ]
        });
    }
    createPwdForm() {
        this.ChangePwdForm = this.formBuilder.group({
            presentPassword: [''],
            oldPassword: [
                '',
                [
                    Validators.required,
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')
                ]
            ],
            newPassword: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')
                ]
            ],
            confirmPassword: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')
                ]
            ],
        },
            {
                validator: PasswordCheckService.validate.bind(this)
            }

        );

    }
    ngAfterViewInit() {
        const style = {
            base: {
                lineHeight: '24px',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#898989'
                }
            },
            invalid: {
                color: '#d0021b'
            },
        };
        this.card = elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }
    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }
    onChange(status) {
        if (status.error) {
            this.error = 'Please enter a valid number';
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }
    editContent() {
        this.profileForm.enable();
        this.disabledField = false;
        this.profileForm.controls['password'].disable();
        this.profileForm.controls['registeredEmail'].disable();
    }
    saveContents() {
        this.profileForm.disable();
        this.disabledField = true;
    }
    // verifyContact() {
    //     this.contactInfo = this.profileForm.get('contact').value;
    // }
    goToSignUp() {
        // Navigate to the sign up page
        this.router.navigate(['/signup']);
    }
    showOrHidePass() {
        if (this.passState) {
            this.passInputType = 'text';
            this.passState = false;
        } else if (this.passState === false) {
            this.passInputType = 'password';
            this.passState = true;
        }
    }
    showOrHideNewPass() {
        if (this.newPassState) {
            this.newPassInputType = 'text';
            this.newPassState = false;
        } else if (this.newPassState === false) {
            this.newPassInputType = 'password';
            this.newPassState = true;
        }
    }
    // verifyMobileNumber() {
    //     this.verifiedNumber = true;
    // }
    openChangePwdModal(template: TemplateRef<any>) {
        this.createPwdForm();
        this.ChangePwdForm.get('presentPassword').setValue(this.userProfile.password);
        this.passState = true;
        this.newPassState = true;
        this.passInputType = 'password';
        this.newPassInputType = 'password';
        this.modalRef = this.modalService.show(template);
    }
    changePassword(form) {
        this.passwordNotSame = this.util.checkPasswords(form);
        if (!this.passwordNotSame) {
            this.modalRef.hide();
        }
        // this.profileForm.get('password').setValue(this.ChangePwdForm.get('confirmPassword').value);
    }
    // openModal(template: TemplateRef<any>) {
    //     this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    //   }
    closeModal(): void {
        this.modalRef.hide();
    }
    selectCountry(cn) {
        this.profileForm.get('billingCountryName').setValue(cn);
    }
}
