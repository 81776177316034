import { Component, OnInit } from '@angular/core';
import { WebsiteWarrantyService } from './../../../services/warrantyForWebsite.service';
import * as moment from 'moment';
import { UtilityService } from './../../../services/utility.service';

@Component({
    selector: 'app-warranty-activity',
    templateUrl: './warranty-activity.component.html',
    styleUrls: ['./warranty-activity.component.scss']
})
export class WarrantyActivityComponent implements OnInit {
    constructor(
        private apiService: WebsiteWarrantyService,
        private util: UtilityService
    ) { }
    public activityDetails;
    getActivityDetails() {
        this.apiService.getActivityDetails().subscribe(data => {
            this.activityDetails = data.activity;
        });
    }

    dateConverter(date) {
        return `${moment(date).format('MMMM D, YYYY, (h:mm a)')} ET`;
    }

    secondsToHms(date) {
        return this.util.secondsToHms(date);
    }

    ngOnInit() {
        this.getActivityDetails();
    }
}
