import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StepperStepsCompletedService {
    private stepperFirstStepCompleted = new BehaviorSubject(false);
    currentFirstStepStatus = this.stepperFirstStepCompleted.asObservable();
    private stepperSecondStepCompleted = new BehaviorSubject(false);
    currentSecondStepStatus = this.stepperSecondStepCompleted.asObservable();
    private stepperThirdStepCompleted = new BehaviorSubject(false);
    currentThirdStepStatus = this.stepperThirdStepCompleted.asObservable();

    constructor() { }

    changeFirstStepperStatus(status: boolean) {
        this.stepperFirstStepCompleted.next(status);
    }
    changeSecondStepperStatus(status: boolean) {
        this.stepperSecondStepCompleted.next(status);
    }
    changeThirdStepperStatus(status: boolean) {
        this.stepperThirdStepCompleted.next(status);
    }
}
