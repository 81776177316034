import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
    env: any;
    constructor(private environment: AppConfigService) {
        this.env = environment.config;
    }

    ngOnInit() {
    }

}
