import { Component, OnInit, NgModule } from '@angular/core';
import { WmStepsCompletedService } from '../../../../services/wmServiceStepsComplete.service';
import { AppConfigService } from '../../../../app-config.service';
@Component({
    selector: 'app-wm-info-navbar',
    templateUrl: './WMInfoNavbar.component.html',
    styleUrls: ['./WMInfoNavbar.component.scss']
})
export class WMInfoNavbarComponent implements OnInit {
    firstStepCompleted: boolean;
    secondStepCompleted: boolean;
    env: any;
    constructor(private wmStepsCompletedService: WmStepsCompletedService, private environment: AppConfigService) {
        this.firstStepCompleted = false;
        this.secondStepCompleted = false;
        this.env = environment.config;
    }

    ngOnInit() {
        this.wmStepsCompletedService.currentFirstStepStatus.subscribe(
            firstStepCompleted => this.firstStepCompleted = firstStepCompleted);
        this.wmStepsCompletedService.currentSecondStepStatus.subscribe(
            secondStepCompleted => this.secondStepCompleted = secondStepCompleted);
    }

}
