import { Component, OnInit } from '@angular/core';
import { WMPurchaseService } from '../../../../services/wmPurchase.service';
import { PassUserInfoService } from '../../../../services/passUserInfo.service';

@Component({
    selector: 'app-wmcheckout-success',
    templateUrl: './wmcheckout-success.component.html',
    styleUrls: ['./wmcheckout-success.component.scss']
})
export class WMCheckoutSuccessComponent implements OnInit {
    additionalWebsites: any;
    userInfo: any;
    purchaseInfo: any;
    constructor(private passUserInfoService: PassUserInfoService, private wmPurchaseService: WMPurchaseService) {
        this.userInfo = this.passUserInfoService.getUserInfo();
        this.purchaseInfo = this.wmPurchaseService.getWMPurchaseInfo();
    }

    ngOnInit() {
        this.additionalWebsites = ['www.finanscorp.com',
            'www.gellertgrindwald.com', 'www.profinance.com', 'www.afunds.com', 'www.flinfin.com'];
    }
    openUrl(site) {
        const url = 'https://' + site;
        window.open(url, '_blank');
    }
}
