import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class WeatherDetail {
    constructor(private http: HttpClient) { }

    public getWeatherDetail(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get('./assets/jsonData/weatherDetail.json');
    }
}
