import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class Revenue {
    range: string;
    minVal: number;
    maxVal: number;
    defVal: number;
    steps: number;
}

@Injectable()
export class WarrantyCoverageCalc {
    revenue: any;
    revenueArr: Array<Revenue> = [];
    constructor(private http: HttpClient) {
    }

    public getWarrantyCoverageRange(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get('./assets/jsonData/warrantyCoverageData.json');
    }
    public getWarrantyRevenueRange(): any {
        for (let i = 1; i <= 25; i++) {
            this.revenue = {};
            if (i === 25) {
                this.revenue.range = '$25 million or more';
            } else {
                this.revenue.range = '$' + i + ' million';
            }
            this.revenue.minVal = 10000;
            this.revenue.maxVal = (i * 1000000) / 50;
            /*  this.revenue.defVal = (this.revenue.maxVal * 80) / 100; */
            /* const first = i * 1000000;
            const sec = ((first / 50) - 10000) / 5000;
            const third = ((sec - 1) * 5000) - 10000;
            console.log(third); */
            const calcDef = (this.revenue.maxVal * 80) / 100;
            const first = Math.ceil(calcDef / 5000) * 5000;
            const second = Math.floor(calcDef / 5000) * 5000;
            if ((first - calcDef) > (calcDef - second)) {
                this.revenue.defVal = second;
            } else if ((first - calcDef) < (calcDef - second)) {
                this.revenue.defVal = first;
            } else {
                this.revenue.defVal = calcDef;
            }
            this.revenue.step = 5000;
            this.revenueArr.push(this.revenue);
        }
        return this.revenueArr;
    }
}
