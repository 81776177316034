import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    AfterContentInit,
} from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap';
import { WebsiteWarrantyService } from './../../../services/warrantyForWebsite.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UploadModalComponent } from './../../common/upload-modal/upload-modal.component';
import { UtilityService } from './../../../services/utility.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-warranty-detail-main',
    templateUrl: './warranty-detail-main.component.html',
    styleUrls: ['./warranty-detail-main.component.scss']
})
export class WarrantyDetailMainComponent implements OnInit {
    @ViewChild('staticTabs')
    staticTabs: TabsetComponent;
    modalRef: BsModalRef;
    autoRenewalWarranty: boolean;
    uploadedDocumentsSectionOpen = false;

    constructor(
        private apiService: WebsiteWarrantyService,
        private modalService: BsModalService,
        private util: UtilityService,
        private router: Router
    ) { }
    public warrantyData;

    getWarrantyDetail() {
        this.apiService.getWarrentyDetails().subscribe(data => {
            this.warrantyData = data;
        });
    }
    receiveAutoRenewalStatus($event) {
        // console.log('$event', $event);
        this.autoRenewalWarranty = $event;
    }
    ngOnInit() {
        this.getWarrantyDetail();
        this.autoRenewalWarranty = true;
    }

    backHandle() {
        this.router.navigate(['existingWarranty']);
    }

    uploadDocument() {
        this.openModal().subscribe(data => {
        });
    }

    openModal() {
        this.modalRef = this.modalService.show(
            UploadModalComponent,
            Object.assign({}, { class: 'gray modal-lg' })
        );
        return new Observable<string>(this.getConfirmSubscriber());
    }

    private getConfirmSubscriber() {
        return observer => {
            const subscription = this.modalService.onHidden.subscribe(
                (reason: string) => {
                    observer.next(this.modalRef.content.answer);
                    observer.complete();
                }
            );

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                }
            };
        };
    }
}
