import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from '../../pages/landing/landing.component';
import { WarrantyInfoParentComponent } from './warrantyInfoParent/warrantyInfoParent.component';
import { WarrantyInfoCoverageComponent } from './warrantyInfoCoverage/warrantyInfoCoverage.component';
import { WarrantyInfoDomainComponent } from './warrantyInfoDomain/warrantyInfoDomain.component';
import { WorkflowGuard } from './workflow/workflow-guard.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './routerReuseStrategy';
import { WarrantycheckoutComponent } from './warrantycheckout/warrantycheckout.component';
import { EmailVerificationComponent } from '../../pages/emailVerification/emailVerify.component';
import { WebpageUrlNotFoundComponent } from '../common/webpageNotFound/webpageUrlNotFound.component';
import { PaymentFailureComponent } from '../../pages/warrantyInfo/paymentFailure/payment-failure.component';


const secondaryRoutes: Routes = [{
    path: 'warranty', // <---- parent component declared here
    component: WarrantyInfoParentComponent,
    children: [
        // <---- child components declared here
        {
            path: 'domain',
            component: WarrantyInfoDomainComponent,
            canActivate: [WorkflowGuard]
        },
        // {
        //   path: 'webpage',
        //   component: WarrantyInfoWebpageComponent,
        //   canActivate: [WorkflowGuard]
        // },
        {
            path: 'coverage',
            component: WarrantyInfoCoverageComponent,
            canActivate: [WorkflowGuard]
        },
        // { path: 'warrantySummary', component: WarrantySummaryComponent, canActivate: [WorkflowGuard] },
        // {
        //     path: 'purchase',
        //     component: WarrantyInfoPurchaseComponent,
        // },
        // { path: 'referenceSite', component: ReferenceSiteListComponent },
        {
            path: 'verify',
            component: EmailVerificationComponent,
            canActivate: [WorkflowGuard]
        },
        { path: 'checkout', component: WarrantycheckoutComponent, canActivate: [WorkflowGuard] },
        { path: 'checkout/failure', component: PaymentFailureComponent, canActivate: [WorkflowGuard] },
        { path: '', redirectTo: '/warranty/domain', pathMatch: 'full' },
        { path: '**', component: WebpageUrlNotFoundComponent },
    ]
}, {
    path: 'weather-endorsement',
    component: WarrantyInfoParentComponent,
    children: [
        {
            path: 'location',
            component: WarrantyInfoDomainComponent,
            canActivate: [WorkflowGuard]
        },
        {
            path: 'payout',
            component: WarrantyInfoCoverageComponent,
            canActivate: [WorkflowGuard]
        },
        {
            path: 'verify',
            component: EmailVerificationComponent,
            canActivate: [WorkflowGuard]
        },
        { path: 'checkout', component: WarrantycheckoutComponent, canActivate: [WorkflowGuard] },
        { path: 'checkout/failure', component: PaymentFailureComponent, canActivate: [WorkflowGuard] },
        { path: '', redirectTo: '/weather-endorsement/location', pathMatch: 'full' },
        { path: '**', component: WebpageUrlNotFoundComponent },
    ]
}];

// taken from angular.io
// Only call RouterModule.forRoot in the root AppRoutingModule (or the AppModule if
// that's where you register top level application routes). In any other module, you
// must call the RouterModule.forChild method to register additional routes.
@NgModule({
    imports: [RouterModule.forChild(secondaryRoutes)],
    exports: [RouterModule],
    providers: [
        WorkflowGuard,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ]
})
export class WarrantyInfoRoutingModule { }
