import { Component, OnInit } from '@angular/core';
import { WMDetailsService } from '../../../services/wmDetails.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../../app-config.service';
@Component({
    selector: 'app-wm-details',
    templateUrl: './WMDetails.component.html',
    styleUrls: ['./WMDetails.component.scss']
})
export class WMDetailsComponent implements OnInit {
    env: any;
    constructor(private wmDetailsService: WMDetailsService, private environment: AppConfigService) {
        this.env = environment.config;
    }
    warrantyInfo: any;
    viewDetails: any;
    ngOnInit() {
        this.wmDetailsService.getWMDetails().subscribe(
            data => {
                this.warrantyInfo = data.info;
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
    }

}
