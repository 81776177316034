import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfigService } from '../../../app-config.service';
import { PassUserInfoService } from '../../../services/passUserInfo.service';

@Component({
    selector: 'app-profile-header',
    templateUrl: './profileHeader.component.html',
    styleUrls: ['./profileHeader.component.scss']
})
export class ProfileHeaderComponent implements OnInit {
    profileHeaderLogoPath: string;
    helpIconPath: string;
    notificationIconPath: string;
    readNotificationIconPath: string;
    logoutIconPath: string;
    userInfo: any = {};
    userName: string;
    showHideHelp: boolean;
    constructor(private router: Router, private environment: AppConfigService, private passUserInfoService: PassUserInfoService) {
        this.profileHeaderLogoPath = environment.config.landingPageLogo;
        this.helpIconPath = environment.config.helpIcon;
        this.notificationIconPath = environment.config.notificationIcon;
        this.readNotificationIconPath = environment.config.notificationUnselected;
        this.logoutIconPath = environment.config.logoutIcon;
        this.showHideHelp = false;
        this.userInfo = this.passUserInfoService.getUserInfo();
        if (this.userInfo !== undefined) {
            this.userName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
        } else {
            this.userName = 'User';
        }

    }
    ngOnInit() {
    }
    goToManageProfile() {
        this.router.navigate(['/manage-profile']);
    }
    goToManageService() {
        this.router.navigate(['/manage-service']);
    }
    // goToNotification() {
    //     this.router.navigate(['/notifications']);
    // }
    showHideHelpBox() {
        if (this.showHideHelp) {
            this.showHideHelp = false;
        } else {
            this.showHideHelp = true;
        }
    }
}
