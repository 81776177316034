import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfigService {
    private appConfig;

    constructor(private injector: Injector) { }

    loadAppConfig() {
        this.appConfig = environment;
    }

    get config() {
        return this.appConfig;
    }
}
