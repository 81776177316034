import { Component, OnInit, NgModule } from '@angular/core';
import { StepperStepsCompletedService } from '../../../services/stepperStepsCompleted.service';
@Component({
    selector: 'app-warranty-info-parent',
    templateUrl: './warrantyInfoParent.component.html',
    styleUrls: ['./warrantyInfoParent.component.scss']
})
export class WarrantyInfoParentComponent implements OnInit {
    constructor() {

    }
    ngOnInit() {
    }

}
