import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ManageProductFlow {
    private selectedUserFlow = new BehaviorSubject('WarrantyPurchase');
    selUserFlow$ = this.selectedUserFlow.asObservable();
    constructor(private http: HttpClient) { }

    setUserFlow(flow: any) {
        this.selectedUserFlow.next(flow);
    }

}
