import { Component, OnInit, NgModule, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AnalysedWebsiteService } from '../../../services/getAnalysedWebsite.service';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';
import { } from '@types/googlemaps';
import { DomSanitizer } from '@angular/platform-browser';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';
import { WeatherLocationFormService } from '../../../services/weatherLocationForm.service';
import { AppConfigService } from '../../../app-config.service';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../common/processCancelModal/processCancelModal.component';
import { UtilityService } from './../../../services/utility.service';
@Component({
    selector: 'app-warranty-info-domain',
    templateUrl: './warrantyInfoDomain.component.html',
    styleUrls: ['./warrantyInfoDomain.component.scss']
})
export class WarrantyInfoDomainComponent implements OnInit {
    locationForm: FormGroup;
    bsModalRef: BsModalRef;
    showWebsiteAnalysingLoader: boolean;
    analysisSuccessfullyCompleted: boolean;
    analysisCompletedError: boolean;
    analysisCompletedWarrantyExist: boolean;
    analysisSuccess: boolean;
    analysisCompletedNoDataAvailable: boolean;
    analysisCompletedLoggedIn: boolean;
    warrantyHasAnotherAccount: boolean;
    analysisCompletedOtherUrl: boolean;
    showAnalyzeButton: boolean;
    emailSubmitText: boolean;
    websiteToProtect: string;
    originalWebUrl: string;
    websiteUrlToSend: string;
    errorPageFormHeader: string;
    smallErrorParaText: string;
    prevRouteErrorPath: string;
    env: any;
    cancelRe: boolean;
    showAllAddresslist: boolean;
    addressSelected: boolean;
    flowData: string;
    allAddressList: any[] = [];
    addr: any;
    address: string;
    options: any;
    error_encountered: boolean;
    errorMessage:string;
    private sub: Subscription;
    warrantyInfoDomainForm = new FormGroup({
        websiteAddressInput: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('[/\\w ]*(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?')
        ])),
        entityCheckboxInput: new FormControl(true),
    });

    quotePriceFormForManualWebAddress = new FormGroup({
        emailAddressForWebAddressInfo: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
    });

    analysisCompletedErrorForm = new FormGroup({
        emailAddressForAnalysisError: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
    });

    constructor(private router: Router, private sanitizer: DomSanitizer,
        private modalService: BsModalService,
        public analysedWebsiteService: AnalysedWebsiteService, private workflowService: WorkflowService, private util: UtilityService,
        private weatherLocationFormService: WeatherLocationFormService, private passWIDInfoService: PassWIDInfoService,
        private environment: AppConfigService, private formBuilder: FormBuilder) {
        this.showWebsiteAnalysingLoader = false;
        this.analysisSuccessfullyCompleted = false;
        this.analysisCompletedError = false;
        this.analysisCompletedOtherUrl = false;
        this.showAnalyzeButton = true;
        this.emailSubmitText = false;
        this.addressSelected = false;
        this.websiteUrlToSend = '';
        this.options = {
            types: [],
            ComponentRestrictions: { country: 'us' }
        };
        this.flowData = sessionStorage.getItem('flow');
        // this.websiteToProtect = this.passWIDInfoService.getDomainName();
        this.sub = this.passWIDInfoService.repurchaseDomain.subscribe(
            domain => {
                this.warrantyInfoDomainForm.get('websiteAddressInput').setValue(domain);
            });
        this.errorPageFormHeader = 'Inform Me When You Have Information To Quote Me A Price!';
        this.prevRouteErrorPath = '/warranty';
        this.env = environment.config;
        this.cancelRe = false;
        this.createLocationForm();
        this.error_encountered = false;
    }
    ngOnInit() {
        if (!this.flowData) {
            this.router.navigate(['/']);
        }

        // this.locationAddressInputChanges();
        // this.warrantyInfoDomainForm.valueChanges.subscribe(
        //     (data) => {
        //         this.warrantyInfoDomainForm.get('websiteAddressInput').setValue(data.websiteAddressInput.trim());
        //     });
        // console.log('environment', this.environment);
    }

    public handleAddressChange(address) {
        // console.log('postal code', this.util.getPostCode(address));

        this.locationForm.controls['streetName'].setValue(
            this.util.getStreetAndStreetNumber(address)
        );
        this.locationForm.controls['cityName'].setValue(
            this.util.getCity(address)
        );
        this.locationForm.controls['stateName'].setValue(
            this.util.getState(address)
        );
        this.locationForm.controls['zip'].setValue(
            this.util.getPostCode(address)
        );
    }
    // locationAddressInputChanges() {
    //         this.locationForm.controls['streetName'].valueChanges
    //         .pipe(debounceTime(200))
    //         .subscribe(addressSearchText => this.weatherLocationFormService.getAllAddressList().subscribe(
    //             data => {
    //                 if (data) {
    //                     this.showAllAddresslist = true;
    //                     this.allAddressList = [];
    //                     if (addressSearchText.length > 0) {
    //                         for (let i = 0; i < data.allAddress.length; i++) {
    //                             if (data.allAddress[i].streetAddress.toUpperCase().includes(addressSearchText.toUpperCase())) {
    //                                 this.allAddressList.push(data.allAddress[i]);
    //                             }
    //                         }
    //                     }
    //                 }
    //             },
    //             (err: HttpErrorResponse) => {
    //                 console.log(err.message);
    //             }
    //         ));
    // }
    // locationAddressKeypressInput(event) {
    //     this.addressSelected = false;
    // }
    // selectedAddress(address) {
    //     this.locationForm.controls['streetName'].setValue(
    //         address.streetAddress
    //     );
    //     this.locationForm.controls['cityName'].setValue(
    //         address.city
    //     );
    //     this.locationForm.controls['stateName'].setValue(
    //         address.state
    //     );
    //     this.locationForm.controls['zip'].setValue(
    //         address.zip
    //     );
    //     this.addressSelected = true;
    // }
    // formatAddressField(address) {
    //     this.addr =  address;
    //     this.address = this.addr.streetAddress + ' ' + this.addr.city
    //     + ', ' + this.addr.state + ', ' + this.addr.country;
    //     return this.address;
    // }
    openModalWithComponent() {
        if (this.flowData === 'WarrantyPurchase') {
            this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, {class : 'cancelModal'});
        } else if (this.flowData === 'WeatherEndPurchase') {
            const initialState = {
                cancelURL: '/',
                cancelButtonHeaderText: 'Cancel endorsement purchase',
                cancelButtonContentText: 'purchase of the endorsement?'
            };
            this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, { initialState, class : 'cancelModal' });
        }

    }
    createLocationForm() {
        this.locationForm = this.formBuilder.group({
            streetName: ['', Validators.compose([Validators.required])],
            cityName: ['', Validators.compose([Validators.required])],
            stateName: ['', Validators.compose([Validators.required])],
            zip: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(5),
                    Validators.minLength(5),
                    Validators.pattern(/^\d{5}(-\d{4})?$/) // chacks for valid zip code
                ])
            ]
        });
    }
    onSubmit() {
        this.showWebsiteAnalysingLoader = true;
        this.analysisSuccessfullyCompleted = false;
        this.analysisCompletedWarrantyExist = false;
        this.analysisCompletedLoggedIn = false;
        this.warrantyHasAnotherAccount = false;
        this.analysisSuccess = false;
        this.analysisCompletedNoDataAvailable = false;
        this.showAnalyzeButton = false;
        this.analysisCompletedError = false;
        
        this.websiteToProtect = this.warrantyInfoDomainForm.value.websiteAddressInput.toLowerCase();

        if(!this.websiteToProtect.includes('http'))
        {
            this.websiteToProtect = "https://"+this.websiteToProtect;
        }
        
        this.warrantyInfoDomainForm.get('websiteAddressInput').setValue(this.websiteToProtect.trim());
        this.analysedWebsiteService.analysedWebsiteList(this.websiteToProtect).subscribe(
            data => {
                if (data) {
                    this.showWebsiteAnalysingLoader = false;
                    this.originalWebUrl = this.websiteToProtect.trim();
                    this.websiteUrlToSend = this.websiteToProtect.trim();
                    this.analysisSuccess = !data.refused;
                    this.error_encountered = data.refused;
                    this.passWIDInfoService.setDomainName(this.websiteUrlToSend);
                    this.showAnalyzeButton =true;
                    
                    if(!this.error_encountered)
                    {
                        this.router.navigate(['/warranty/coverage']);
                    }
                    else
                    {
                        this.errorMessage = "Unfortunately we cannot offer WID for this website because "+data.rejection_reason;
                    }

                    this.analysedWebsiteService.setData(data);
                    this.workflowService.validateStep(STEPS.domain);
                    this.warrantyInfoDomainForm.reset();
                    this.warrantyInfoDomainForm.get('entityCheckboxInput').setValue(true);

                    // this.router.navigate(['/warranty/coverage']);
                }
            },
            (err: HttpErrorResponse) => {
                this.error_encountered = true;
                this.errorMessage = "Sorry, quote for this website is not found.";
                this.showWebsiteAnalysingLoader = false;
            });

    }
    onLocationFormSubmit() {
        this.showWebsiteAnalysingLoader = true;

        this.address = this.locationForm.controls['streetName'].value + ' ' + this.locationForm.controls['cityName'].value
                        + ', ' + this.locationForm.controls['stateName'].value+ ', ' + this.locationForm.controls['zip'].value;

        this.weatherLocationFormService.getQuotes(this.address).subscribe(
            data=>{
                if(data)
                {
                    this.showWebsiteAnalysingLoader = false;

                    this.error_encountered = data.refused;

                    if(!data.refused)
                    {
                        this.weatherLocationFormService.setBuldingLocationInfo(this.locationForm.value);
                        this.router.navigate(['/weather-endorsement/payout']);
                        this.workflowService.validateStep(STEPS.domain);
                    }
                    else
                    {
                        this.errorMessage = "Unfortunately the nearest weather station is "+data.stn_name+ " at a distance of "+data.distance+" miles. We do not cover addresses this far.";
                    }

                    this.weatherLocationFormService.setApiData(data);
                    this.locationForm.reset();
                }        
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
                this.error_encountered = true;
                this.errorMessage = "Sorry, quote for this address is not found.";
                this.showWebsiteAnalysingLoader = false;
                this.locationForm.reset();
            });
    }
    // changed = (evt) => {
    //   console.log(evt.target.checked)
    //   this.isChecked = evt.target.checked;
    //   console.log(this.isChecked)
    //   }
    enableFormInput() {
        this.warrantyInfoDomainForm.controls['websiteAddressInput'].enable();
        this.warrantyInfoDomainForm.controls['entityCheckboxInput'].enable();
        this.warrantyInfoDomainForm.get('websiteAddressInput').reset('');
        this.warrantyInfoDomainForm.get('entityCheckboxInput').reset(true);
        this.analysisCompletedErrorForm.get('emailAddressForAnalysisError').reset('');
        this.analysisSuccessfullyCompleted = false;
        this.showAnalyzeButton = true;
        this.analysisCompletedError = false;
        this.analysisCompletedOtherUrl = false;
        this.analysisCompletedWarrantyExist = false;
        this.analysisCompletedLoggedIn = false;
        this.warrantyHasAnotherAccount = false;
        this.analysisSuccess = false;
        this.analysisCompletedNoDataAvailable = false;
    }
    onEmailSubmit() {
        this.emailSubmitText = true;
    }

    goToNext() {
        // Navigate to the work page
        this.passWIDInfoService.setDomainName(this.websiteUrlToSend);
        // console.log('this.websiteToProtect.trim()', this.websiteToProtect);
        this.router.navigate(['/warranty/coverage']);

    }
    // openUrl(site) {
    //     const website = 'https://' + site;
    //     window.open(website, '_blank');
    // }
    cancelHandle() {
        this.cancelRe = true;
        // if (this.cancelRe === true) {
        //     this.cancelRe = false;
        // } else {
        //     this.cancelRe = true;
        // }
        console.log('solving', this.cancelRe);
    }

    resetLocationForm()
    {
        this.locationForm.reset();
        this.error_encountered = false;
    }

    resetWarrantyForm()
    {
        this.warrantyInfoDomainForm.reset();
        this.error_encountered = false;
        this.enableFormInput();
    }
}
