import { Component, OnInit } from '@angular/core';
import { WarrantyHistoryDetailService } from '../../../services/warrantyHistoryDetail.service';
// import * as moment from 'moment';
import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../../services/utility.service';
@Component({
    selector: 'app-endorse-billing',
    templateUrl: './endorse-billing.component.html',
    styleUrls: ['./endorse-billing.component.scss']
})
export class EndorseBillingComponent implements OnInit {
    private serviceDetailSubscription: Subscription;
    billingHistoryData: any[];
    selectedService: any = {};
    env: any;
    constructor(private warrantyHistoryDetailService: WarrantyHistoryDetailService, public util: UtilityService,
        private environment: AppConfigService,
        private manageDetails: ManageDetailsService) {
        this.env = environment.config;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
                if (this.selectedService.billingHistory) {
                    this.billingHistoryData = this.selectedService.billingHistory;
                }
            });
    }
    ngOnInit() {
    }

    receiptDownload() {
        window.open(this.env.receiptPdf, '_blank');
        // console.log('receipt will download');
    }
}
