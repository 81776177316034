import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-small-error-text',
    templateUrl: './errorText.component.html',
    styleUrls: ['./errorText.component.scss']
})
export class ErrorTextComponent implements OnInit {
    @Input()
    smallErrorText: string;
    constructor() { }
    ngOnInit() { }
}
