import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanLoad, Route
} from '@angular/router';

import { WMWorkflowService } from './WMWorkflow.service';

@Injectable()
export class WMWorkflowGuard implements CanActivate {
    constructor(private router: Router, private wmWorkflowService: WMWorkflowService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path: string = route.routeConfig.path;
        return this.verifyWorkFlow(path);
    }

    verifyWorkFlow(path): boolean {
        console.log('Entered "' + path + '" path.');
        // If any of the previous steps is invalid, go back to the first invalid step
        const firstPath = this.wmWorkflowService.getFirstInvalidStep(path);
        if (firstPath.length > 0) {
            console.log('Redirected to "' + firstPath + '"path which it is the first invalid step.');
            const url = `/wm/${firstPath}`;
            console.log([url], '[url]');
            this.router.navigate([url]);
            return false;
        }
        return true;
    }
}
