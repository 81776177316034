import { Component, OnInit } from '@angular/core';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
@Component({
    selector: 'app-endorse-payout',
    templateUrl: './endorse-payout.component.html',
    styleUrls: ['./endorse-payout.component.scss']
})
export class EndorsePayoutComponent implements OnInit {
    private serviceDetailSubscription: Subscription;
    selectedService: any = {};
    payoutForm: FormGroup;
    formDisabled: boolean;
    constructor(private formBuilder: FormBuilder, private manageDetails: ManageDetailsService) {
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
            });
        this.createForm();
        this.payoutForm.get('bankName').setValue(this.selectedService.bankName);
        this.payoutForm.get('acHolderName').setValue(this.selectedService.acHolderName);
        this.payoutForm.get('accNumber').setValue(this.selectedService.accNumber);
        this.payoutForm.get('routingNo').setValue(this.selectedService.routingNo);
        this.payoutForm.disable();
        this.formDisabled = true;
    }
    createForm() {
        this.payoutForm = this.formBuilder.group({
            bankName: ['', Validators.compose([Validators.required, Validators.pattern('(?!^ +$)^.+$')])],
            acHolderName: ['', Validators.compose([Validators.required, Validators.pattern('(?!^ +$)^.+$')])],
            accNumber: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
            routingNo: ['', Validators.compose([Validators.required, Validators.maxLength(9), Validators.pattern('[0-9]*')])]
        });
    }
    saveContent() {
        if (this.payoutForm.invalid) {
            return;
        } else {
            this.payoutForm.disable();
            this.formDisabled = true;
        }
    }
    editContent() {
        this.payoutForm.enable();
        this.formDisabled = false;
    }
    ngOnInit() {
    }

}
