import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';
import { PassUserInfoService } from '../../services/passUserInfo.service';
import { WorkflowService } from '../warrantyInfo/workflow/workflow.service';
import { STEPS } from '../warrantyInfo/workflow/workflow.model';

@Component({
    selector: 'app-verify-email',
    templateUrl: './emailVerify.component.html',
    styleUrls: ['./emailVerify.component.scss']
})
export class EmailVerificationComponent implements OnInit {
    passState = 'Show';
    passInputType = 'password';
    verifyEmailForm: FormGroup;
    user: any = {};
    userMailId: string;
    submitted = false;
    constructor(private formBuilder: FormBuilder, private router: Router,
        private workflowService: WorkflowService,
        private environment: AppConfigService, private passUserInfoService: PassUserInfoService) {
        this.user = this.passUserInfoService.getUserInfo();
        if (this.user != null) {
            this.userMailId = this.user.emailId;
        } else {
            this.userMailId = 'user';
        }
    }

    ngOnInit() {
        this.verifyEmailForm = this.formBuilder.group({
            verificationCode: ['', [Validators.required, Validators.maxLength(6),
            Validators.minLength(6), Validators.pattern(/^\d{6}?$/)]]
        });
    }
    get f() {
        return this.verifyEmailForm.controls;
    }

    get emailId() {
        return this.verifyEmailForm.get('verificationCode');
    }
    onSubmit() {
        this.submitted = true;
        const flowData = sessionStorage.getItem('flow');
        if (flowData === 'WarrantyPurchase') {
            this.router.navigate(['/warranty/checkout']);
            this.workflowService.validateStep(STEPS.verify);
        } else if (flowData === 'WebMonitoringPurchase') {
            this.router.navigate(['/wm/wmCheckout']);
        } else if (flowData === 'LandingPageSignUp') {
            this.router.navigate(['/existingWarranty']);
        } else if (flowData === 'WeatherEndPurchase') {
            this.router.navigate(['/weather-endorsement/checkout']);
            this.workflowService.validateStep(STEPS.verify);
        }
    }

}

