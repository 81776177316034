import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';
import { PassUserInfoService } from '../../services/passUserInfo.service';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit {
    passInputType = 'password';
    signUpForm: FormGroup;
    passState: boolean;
    showPasswordValidationtext: boolean;
    user: any = {};
    submitted = false;
    env: any;
    userInfo: any;
    constructor(private formBuilder: FormBuilder, private router: Router,
        private environment: AppConfigService, private passUserInfoService: PassUserInfoService, private location: PlatformLocation) {
        this.userInfo = {};
        this.env = environment.config;
        this.showPasswordValidationtext = false;
        this.passState = true;
        location.onPopState(() => {
            this.signUpForm.reset();
        });
    }

    ngOnInit() {
        this.signUpForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.pattern('(?!^ +$)^.+$')]],
            lastName: ['', [Validators.required, Validators.pattern('(?!^ +$)^.+$')]],
            emailId: ['', [Validators.required, Validators.email,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-,_,\',",;,:,!,@,#,$,%,^,&,*,(,),[,\\],\?,{,},|,+,=,<,>,~,`,\\\\,\,,\/,.])[a-zA-Z0-9-,_,\',",;,:,!,@,#,$,%,^,&,*,(,),[,\\],\?,{,},|,+,=,<,>,~,`,\\\\,\,,\/,.]+$')
                    //    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()_<>{}?/.,;:\|+])[a-zA-Z0-9~!@#$%^&*()_<>{}?/.,;:\|+]+$')
                    // pattern for checking password validation
                ]
            ]
        });
    }
    get f() { return this.signUpForm.controls; }

    get emailId() {
        return this.signUpForm.get('emailId');
    }

    get password() {
        return this.signUpForm.get('password');
    }
    get firstName() {
        return this.signUpForm.get('firstName');
    }

    get lastName() {
        return this.signUpForm.get('lastName');
    }

    showOrHidePass() {
        if (this.passState) {
            this.passInputType = 'text';
            this.passState = false;
        } else if (!this.passState) {
            this.passInputType = 'password';
            this.passState = true;
        }
    }
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.signUpForm.invalid) {
            return;
        } else {
            this.userInfo.firstName = this.signUpForm.get('firstName').value;
            this.userInfo.lastName = this.signUpForm.get('lastName').value;
            this.userInfo.emailId = this.signUpForm.get('emailId').value;
            this.passUserInfoService.setUserInfo(this.userInfo);

            const flowData = sessionStorage.getItem('flow');
            if (flowData === 'WarrantyPurchase') {
                this.router.navigate(['/warranty/verify']);
            } else if (flowData === 'WebMonitoringPurchase') {
                this.router.navigate(['/wm/verify']);
            } else if (flowData === 'LandingPageSignUp') {
                this.router.navigate(['/verify']);
            } else if (flowData === 'WeatherEndPurchase') {
                this.router.navigate(['/weather-endorsement/verify']);
            }
            this.signUpForm.reset();
        }
    }


}
