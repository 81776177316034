import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class PasswordCheckService {
    static validate(ChangePwdForm: FormGroup) {
        const newPassword = ChangePwdForm.controls.newPassword.value;
        // const repeatPassword = ChangePwdForm.controls.confirmPassword.value;
        const oldPassword = ChangePwdForm.controls.oldPassword.value;
        const presentPassword = ChangePwdForm.controls.presentPassword.value;
        if (oldPassword.length <= 0) {
            return null;
        }
        if (oldPassword !== presentPassword) {
            return { oldPasswordNotMatch: true };
        }
        if (oldPassword === newPassword) {
            return { sameOldNewPassword: true };
        }
        // if (repeatPassword.length <= 0) {
        //     return null;
        // }
        // if (repeatPassword !== newPassword) {
        //     return {
        //         passwordNotMatch: true
        //     };
        // }
        return null;

    }
}
