import { Component, OnInit, ViewChild, TemplateRef, AfterContentInit } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-manage-warranty-main',
    templateUrl: './manage-warranty-main.component.html',
    styleUrls: ['./manage-warranty-main.component.scss']
})
export class ManageWarrantyMainComponent implements OnInit {
    private serviceDetailSubscription: Subscription;
    env: any;
    serviceDetail: any = {};
    payoutPendingState: boolean;
    tabNumber: number;
    @ViewChild('staticTabs')
    staticTabs: TabsetComponent;

    constructor(private environment: AppConfigService, private router: Router, private manageDetails: ManageDetailsService
    ) {

        this.env = environment.config;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.serviceDetail = service;
            });
        this.serviceDetailSubscription = this.manageDetails.payoutPending.subscribe(
            service => {
                this.payoutPendingState = service;
            });
    }
    //   selectTab(tabId: number) {
    //   this.staticTabs.tabs[tabId].active = true;
    // }
    ngOnInit() {
        this.serviceDetailSubscription = this.manageDetails.changeTab.subscribe(
            tabIDs => {
                this.tabNumber = tabIDs;
                this.staticTabs.tabs[this.tabNumber].active = true;
            });
    }
}
