import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-webpage-url-not-found',
    templateUrl: './webpageUrlNotFound.component.html',
    styleUrls: ['./webpageUrlNotFound.component.scss']
})

export class WebpageUrlNotFoundComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
