import { Component, OnInit } from '@angular/core';
import { ExistingWarrantySummary } from '../../services/existingWarrantyInfo.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TypeaheadOptions } from 'ngx-bootstrap';

@Component({
    selector: 'app-existing-warranty',
    templateUrl: './existingWarranty.component.html',
    styleUrls: ['./existingWarranty.component.scss']
})
export class ExistingWarrantyComponent implements OnInit {
    warrantyList: any[];
    statusStyle: any;
    existingWarrantyStatus: boolean;
    constructor(private existingWarrantySummary: ExistingWarrantySummary) { }

    ngOnInit() {
        this.existingWarrantySummary.getSummary().subscribe(
            data => {
                this.warrantyList = data.Warranties;
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
        this.existingWarrantyStatus = false;
    }
    onSubmit() { }
}
