import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    AfterContentInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ManageDetailsService } from '../../services/manageServices.service';
import { AppConfigService } from '../../app-config.service';
import { PassWIDInfoService } from '../../services/passWIDInfo.service';
import { AllRiskDetailService } from '../../services/allRiskDetails.service';
@Component({
    selector: 'app-manage-service',
    templateUrl: './manageService.component.html',
    styleUrls: ['./manageService.component.scss']
})
export class ManageServiceComponent implements OnInit {
    @ViewChild('staticTabs')
    autoRenewalWarranty: boolean;
    uploadedDocumentsSectionOpen = false;
    services: any;
    env: any;
    selectedService: any;
    allRisks: any;
    constructor(
        private router: Router,
        private manageDetails: ManageDetailsService,
        private environment: AppConfigService,
        private passWIDInfoService: PassWIDInfoService,
        public allRiskDetailService: AllRiskDetailService,
    ) {
        this.env = environment.config;
        this.selectedService = {};
        this.manageDetails.getServices().subscribe(
            data => {
                this.services = data.services;
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
    }

    receiveAutoRenewalStatus($event) {
        this.autoRenewalWarranty = $event;
    }
    ngOnInit() {
        this.autoRenewalWarranty = true;
        this.allRiskDetailService.allRiskDetailsList().subscribe(
            data => {
                if (data) {
                    this.allRisks = data.onlineRisks;
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            }
        );
    }
    viewDetails(service) {
        this.selectedService = service;
        console.log('this.selectedService', this.selectedService);
        this.manageDetails.setService(this.selectedService);
        if (this.selectedService.type === 'WID Contract') {
            this.router.navigate(['/manage-warranty']);
        } else if (this.selectedService.type === 'Website Monitoring') {
            this.router.navigate(['/manage-monitor']);
        } else if (this.selectedService.type === 'Weather Endorsement') {
            this.router.navigate(['/manage-endorsement']);
        }
    }
    backHandle() {
        this.router.navigate(['existingWarranty']);
    }
    openUrl(service) {
        const site = service.website;
        // let site = 'https://' + service.website;
        // if (service.urlType !== undefined && service.urlType !== null) {
        //     if (service.urlType.length === 2) {
        //         site = 'https://' +  service.website;
        //     } else if (service.urlType.length === 1) {
        //         if (service.urlType[0] === 'HTTPS') {
        //             site = 'https://' +  service.website;
        //         }
        //         if (service.urlType[0] === 'HTTP') {
        //             site = 'http://' +  service.website;
        //         }
        //     }
        // }
        window.open(site, '_blank');
    }
    repurchaseService(service) {
        this.passWIDInfoService.setRepurchaseDomain(service.websiteName);
        this.router.navigate(['/warranty/domain']);
    }
    routeToWarrantyDetails(item) {
        if (item.riskName === 'WID') {
            sessionStorage.setItem('flow', 'WarrantyPurchase');
        } else if (item.riskName === 'WEATHER ENDORSEMENT') {
            sessionStorage.setItem('flow', 'WeatherEndPurchase');
        }
    }
}
