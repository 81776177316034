import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-warranty-info-common-error-page',
    templateUrl: './warrantyInfoCommonError.component.html',
    styleUrls: ['./warrantyInfoCommonError.component.scss']
})
export class WarrantyInfoCommonErrorComponent implements OnInit {
    @Input()
    quotePriceFormManualEmailAddress: FormGroup;
    @Input()
    errorPageFormHeader: string;
    @Input()
    text: string;
    @Input()
    prevRouteForWebErrorPage: string;
    constructor(private router: Router) { }
    websiteNotFoundMailSubmit() {

    }
    ngOnInit() { }
    goToPrevious(prevroute) {
        // Navigate to the work page
        this.router.navigate([prevroute]);
    }
}
