import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-short-footer',
    templateUrl: './shortFooter.component.html',
    styleUrls: ['./shortFooter.component.scss']
})
export class ShortFooterComponent implements OnInit {
    currentYear = new Date().getFullYear();
    constructor() { }

    ngOnInit() { }
}
