import { Component, OnInit, NgModule, ViewEncapsulation } from '@angular/core';
import { ProfileNotificationService } from '../../services/profileNotification.service';
import * as moment from 'moment';
import { AppConfigService } from '../../app-config.service';

@Component({
    selector: 'app-profile-notifications',
    templateUrl: './profileNotifications.component.html',
    styleUrls: ['./profileNotifications.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileNotificationsComponent implements OnInit {
    loadMoreNotifications: any[];
    counter: number;
    profileNotifications: any[];
    moreNotificationToShow: number;
    env: any;
    constructor(private getNotificationService: ProfileNotificationService, private environment: AppConfigService) {
        this.moreNotificationToShow = 10;
        this.env = environment.config;
    }
    notificationDateConverter(date) {
        return `${moment(date).format('MMMM D, YYYY')}`;
    }
    getNotification() {
        this.getNotificationService.getProfileNotification().subscribe(data => {
            this.profileNotifications = data.notificatonData;
            this.getMoreNotifications();
        });
    }
    getMoreNotifications() {
        if (this.profileNotifications.length > 0) {
            for (let i = this.counter + 1; i < this.profileNotifications.length; i++) {
                this.loadMoreNotifications.push(this.profileNotifications[i]);
                if (i % this.moreNotificationToShow === 0) {
                    break;
                }
            }
            this.counter += 10;
        }
    }

    ngOnInit() {
        this.profileNotifications = [];
        this.loadMoreNotifications = [];
        this.getNotification();
        this.counter = 0;
    }
}
