import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppConfigService } from '../../app-config.service';
import { UtilityService } from '../../services/utility.service';
@Component({
    selector: 'app-create-new-password',
    templateUrl: './create-new-password.component.html',
    styleUrls: ['./create-new-password.component.scss']
})
export class CreateNewPasswordComponent implements OnInit {
    ChangePwdForm: FormGroup;
    env: any;
    passInputType = 'password';
    passState: boolean;
    showPasswordValidationtext: boolean;
    passwordNotSame: boolean;
    constructor(private formBuilder: FormBuilder, private router: Router, private environment: AppConfigService,
        private util: UtilityService) {
        this.env = environment.config;
        this.passState = true;
        this.showPasswordValidationtext = false;
    }

    ngOnInit() {
        this.createPwdForm();
    }
    createPwdForm() {
        this.ChangePwdForm = this.formBuilder.group({
            newPassword: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')
                ]
            ],
            confirmPassword: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$'),
                ]
            ],
        },
            // { validator: this.checkPasswords }
        );
    }
    // checkPasswords(group: FormGroup) {
    //     const pass = group.controls.newPassword.value;
    //     const confirmPass = group.controls.confirmPassword.value;
    //     if (confirmPass.length <= 0) {
    //         return false;
    //     }
    //     return pass === confirmPass ? false :  true ;
    //   }
    showOrHidePass() {
        if (this.passState) {
            this.passInputType = 'text';
            this.passState = false;
        } else if (this.passState === false) {
            this.passInputType = 'password';
            this.passState = true;
        }
    }
    onSubmit(form) {
        this.passwordNotSame = this.util.checkPasswords(form);
        if (!this.passwordNotSame) {
            this.router.navigate(['/login']);
        }
    }
}
