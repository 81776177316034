import { Component, OnInit, NgModule } from '@angular/core';
import { StepperStepsCompletedService } from '../../../../services/stepperStepsCompleted.service';
@Component({
    selector: 'app-wm-info-parent',
    templateUrl: './WMInfoParent.component.html',
    styleUrls: ['./WMInfoParent.component.scss']
})
export class WMInfoParentComponent implements OnInit {
    constructor() {
    }
    ngOnInit() {
    }

}
