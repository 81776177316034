import { Component, OnInit } from '@angular/core';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../app-config.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'app-monitor-activity',
    templateUrl: './monitor-activity.component.html',
    styleUrls: ['./monitor-activity.component.scss']
})

export class MonitorActivityComponent implements OnInit {
    private serviceDetailSubscription: Subscription;
    selectedService: any = {};
    websiteOptions: any[] = [];
    timePeriodOptions: any[] = [];
    compareSiteToggle: boolean;
    changeFormatType: string;
    env: any;
    selectedTimePeriod: any;
    selectedSite: any;
    constructor(private manageDetails: ManageDetailsService, public environment: AppConfigService) {
        this.compareSiteToggle = false;
        this.env = environment.config;
        this.changeFormatType = 'graphical';
        this.timePeriodOptions = [{ 'time': 'Yesterday', 'value': 'Yesterday' }, { 'time': 'Last Week ', 'value': 'Last Week' },
        { 'time': 'Last Month', 'value': 'Last Month' }];
        this.selectedTimePeriod = { 'time': 'Yesterday', 'value': 'Yesterday' };
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.websiteOptions = [];
                this.selectedService = service;
                let siteObj = {};
                siteObj['name'] = service['website'];
                siteObj['value'] = service['website'];
                this.websiteOptions.push(siteObj);
                this.selectedSite = siteObj;
                if (service['secondarySites']) {
                    for (let i = 0; i < service['secondarySites'].length; i++) {
                        siteObj = {};
                        siteObj['name'] = service['secondarySites'][i].secWebsite;
                        siteObj['value'] = service['secondarySites'][i].secWebsite;
                        this.websiteOptions.push(siteObj);
                    }
                }
            });
    }

    ngOnInit() {
    }
    compareSite() {

    }
    websiteChange(event) {

    }
    periodChange(event) {

    }
    selectTimePeriod(tp) {
        this.selectedTimePeriod = tp;
    }
    selectWebsite(website) {
        this.selectedSite = website;
    }
}
