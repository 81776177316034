import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-warranty-purchase-error-page',
    templateUrl: './warrantyPurchaseError.component.html',
    styleUrls: ['./warrantyPurchaseError.component.scss']
})
export class WarrantyPurchaseErrorComponent implements OnInit {
    constructor(private router: Router) { }

    ngOnInit() { }
}
