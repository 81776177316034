import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../app-config.service';
import { UtilityService } from './../../../services/utility.service';

@Component({
    selector: 'app-wid-payment-failure',
    templateUrl: './payment-failure.component.html',
    styleUrls: ['./payment-failure.component.scss']
})
export class PaymentFailureComponent implements OnInit {
    env: any;
    constructor(private environment: AppConfigService, public util: UtilityService) {
        this.env = environment.config;
    }

    ngOnInit() {
    }
    goToPrevious() {
        this.util.previousHandle();
    }
}



