import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { AppConfigService } from '../../../app-config.service';

@Component({
    selector: 'app-manage-activity',
    templateUrl: './manage-activity.component.html',
    styleUrls: ['./manage-activity.component.scss']
})

export class ManageActivityComponent implements OnInit, OnDestroy {
    private sub: Subscription;
    timePeriodOptions: any[] = [];
    selectedService: any = {};
    selectedTimePeriod: null;
    env: any;
    changeURLTypeData: string;
    constructor(private environment: AppConfigService, private manageDetails: ManageDetailsService) {
        this.timePeriodOptions = [{ 'time': 'Oct 1, 2018 - Oct 31, 2018 ', 'value': 'Oct 1, 2018 - Oct 31, 2018 ' },
        { 'time': 'Sep 1, 2018 - Sep 30, 2018 ', 'value': 'Sep 1, 2018 - Sep 30, 2018 ' }];
        this.sub = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
            });
        this.env = environment.config;
        this.changeURLTypeData = 'HTTP';
        if (this.selectedService.website === 'https://www.julia.com') {
            this.selectedTimePeriod = this.timePeriodOptions[1];
        } else {
            this.selectedTimePeriod = this.timePeriodOptions[0];
        }
    }

    ngOnInit() {
    }
    timePeriodChange(event) {
    }
    selectTimePeriod(tp) {
        this.selectedTimePeriod = tp;
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    changeUrlData(urlType) {
        this.changeURLTypeData = urlType;
        console.log(urlType);
    }

}
