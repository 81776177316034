import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    env: any;
    constructor(private environment: AppConfigService) {
        this.env = environment.config;
    }

    ngOnInit() {
    }

}
