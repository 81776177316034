import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WmStepsCompletedService {
    private stepperFirstStepCompleted = new BehaviorSubject(false);
    currentFirstStepStatus = this.stepperFirstStepCompleted.asObservable();
    private stepperSecondStepCompleted = new BehaviorSubject(false);
    currentSecondStepStatus = this.stepperSecondStepCompleted.asObservable();

    constructor() { }

    changeFirstStepperStatus(status: boolean) {
        this.stepperFirstStepCompleted.next(status);
    }
    changeSecondStepperStatus(status: boolean) {
        this.stepperSecondStepCompleted.next(status);
    }
}
