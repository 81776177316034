import { Component, OnInit, NgModule } from '@angular/core';
import { AnalysedWebsiteService } from '../../../services/getAnalysedWebsite.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';
import { Router } from '@angular/router';
import { WebsiteWarrantyService } from '../../../services/warrantyForWebsite.service';
import { StepperStepsCompletedService } from '../../../services/stepperStepsCompleted.service';

@Component({
    selector: 'app-warranty-info-webpage',
    templateUrl: './warrantyInfoWebpage.component.html',
    styleUrls: ['./warrantyInfoWebpage.component.scss']
})
export class WarrantyInfoWebpageComponent implements OnInit {
    webSiteData: any;
    errorPageFormHeader: string;
    showWebsiteWarrantyLoader: boolean;
    webAddressToAnalyse: string;
    analysedWebsiteNameForm = new FormGroup({
        websiteAddressRadioInput: new FormControl(''),
    });
    customWebsiteAddressForm = new FormGroup({
        customWebsiteAddressInput: new FormControl('', Validators.compose([
            Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
        ])),
    });
    emailFormForWebsiteNotFindToCover = new FormGroup({
        emailAddressForWebAddressInfo: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
    });
    constructor(private router: Router, private stepperStepsCompletedService: StepperStepsCompletedService,
        public websiteWarrantyService: WebsiteWarrantyService, private analysedWebsiteService: AnalysedWebsiteService,
        private workflowService: WorkflowService) {
        this.analysedWebsiteService.apiData$.subscribe(
            data => {
                if (data) {
                    this.webSiteData = data.allWebsite;
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
        this.showWebsiteWarrantyLoader = false;
        this.webAddressToAnalyse = '';
        this.errorPageFormHeader = 'Inform Me When You Can Offer Me A Contract!';
    }
    stepperStatusChanged() {
        this.stepperStepsCompletedService.changeFirstStepperStatus(true);
    }

    ngOnInit() {
        this.stepperStatusChanged();
    }
    analysedWebsiteFormSubmit() {
        this.showWebsiteWarrantyLoader = true;
        this.webAddressToAnalyse = this.analysedWebsiteNameForm.value.websiteAddressRadioInput;
        // this.workflowService.validateStep(STEPS.webpage);
        this.websiteWarrantyService.WebsiteWarrantyFunctionCall().subscribe(
            data => {
                if (data) {
                    this.showWebsiteWarrantyLoader = false;
                    this.router.navigate(['/warranty/coverage']);
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
    }
    customWebsiteAddressSubmit() {
        // this.workflowService.validateStep(STEPS.webpage);
        this.router.navigate(['/warranty/coverage']);
    }
    goToPrevious() {
        // Navigate to the domain page
        this.router.navigate(['/warranty/domain']);

    }
}
