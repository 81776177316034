import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  currentRoute = '';
  routes = {
    WID: 'wid',
    WIND: 'wind'
  };

  constructor() { }

  ngOnInit() {
    for (let route of Object.values(this.routes)) {
      if (window.location.host.startsWith(route)) {
        this.currentRoute = route;
      }
    }
  }

}
