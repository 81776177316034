import { Component, OnInit } from '@angular/core';
import { WarrantyHistoryDetailService } from '../../../services/warrantyHistoryDetail.service';
// import * as moment from 'moment';
import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
import { UtilityService } from './../../../services/utility.service';
@Component({
    selector: 'app-manage-billing',
    templateUrl: './manage-billing.component.html',
    styleUrls: ['./manage-billing.component.scss']
})
export class ManageBillingComponent implements OnInit {
    private serviceDetailSubscription: Subscription;
    billingHistoryData: any[];
    selectedService: any = {};
    showHideCancelBox: boolean;
    env: any;
    constructor(private warrantyHistoryDetailService: WarrantyHistoryDetailService, public util: UtilityService,
        private environment: AppConfigService,
        private manageDetails: ManageDetailsService) {
        this.showHideCancelBox = true;
        this.env = environment.config;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
                if (this.selectedService.billingHistory) {
                    this.billingHistoryData = this.selectedService.billingHistory;
                }
            });
    }
    ngOnInit() {
        // this.getWarrantyDetail();
        this.showHideCancelBox = true;
    }
    // getWarrantyDetail() {
    //     this.warrantyHistoryDetailService.WarrantyHistoryDetailData().subscribe(data => {
    //         this.billingHistoryData = data.warrantyHistoryData;
    //     });
    // }
    // historyDateConverter(date) {
    //     return `${moment(date).format('MMM D, YYYY')}`;
    // }
    receiptDownload() {
        window.open(this.env.receiptPdf, '_blank');
        // console.log('receipt will download');
    }
    cancelWarranty() {
        this.showHideCancelBox = false;
    }
}
