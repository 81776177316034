import { Component, OnInit, OnDestroy } from '@angular/core';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../app-config.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ng5SliderModule, Options, ChangeContext, PointerType } from 'ng5-slider';
@Component({
    selector: 'app-monitor-manage',
    templateUrl: './monitor-manage.component.html',
    styleUrls: ['./monitor-manage.component.scss']
})
export class MonitorManageComponent implements OnInit, OnDestroy {
    private serviceDetailSubscription: Subscription;
    selectedService: any;
    env: any;
    siteList: any;
    editInfo: boolean;
    thresholdOption: Options = {};
    thresholdTime: any;
    editMblIcon: string;
    saveMblIcon: string;
    PrimarySiteEditForm = new FormGroup({
        primarywebsiteAddressInput: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?')
        ])),
    });

    constructor(private manageDetails: ManageDetailsService, private environment: AppConfigService, ) {
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
                if (this.selectedService.secondarySites !== undefined) {
                    this.siteList = this.selectedService.secondarySites;
                }
            });
        this.env = this.environment.config;
        this.editInfo = false;
        this.thresholdOption = {
            floor: 5,
            ceil: 50,
            step: 5,
            showSelectionBar: true,
            autoHideLimitLabels: false,
            showTicks: true,
            translate: (value: number): string => {
                return value + ' sec';
            }
        };
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        this.serviceDetailSubscription.unsubscribe();
    }
    editPrimaryInfo() {
        this.editInfo = true;
        this.PrimarySiteEditForm.setValue({ primarywebsiteAddressInput: this.selectedService.website });
        this.thresholdTime = this.selectedService.alertThresholdVal;
    }
    savePrimaryInfo() {
        this.editInfo = false;
        this.selectedService.website = this.PrimarySiteEditForm.value.primarywebsiteAddressInput;
        this.selectedService.alertThreshold = this.thresholdTime + ' seconds';
    }
    removeUrlFromList(site) {
        for (let i = 0; i < this.siteList.length; i++) {
            if (this.siteList[i].secWebsite === site.secWebsite) {
                this.siteList.splice(i, 1);
            }
        }
    }
    openUrl(site) {
        window.open(site, '_blank');
    }
}
