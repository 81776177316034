import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../app-config.service';
@Component({
    selector: 'app-short-header',
    templateUrl: './shortHeader.component.html',
    styleUrls: ['./shortHeader.component.scss']
})

export class ShortHeaderComponent implements OnInit {
    headerLogoPath: string;
    helpIconPath: string;
    showHideHelp: boolean;
    constructor(private environment: AppConfigService) {
        this.headerLogoPath = environment.config.landingPageLogo;
        this.helpIconPath = environment.config.helpIcon;
        this.showHideHelp = false;
    }

    ngOnInit() {
    }
    showHideHelpBox() {
        if (this.showHideHelp) {
            this.showHideHelp = false;
        } else {
            this.showHideHelp = true;
        }
    }
}
