import {
    Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild, DoCheck,
    ElementRef
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { Location } from '@angular/common';
import { StepperStepsCompletedService } from '../../../services/stepperStepsCompleted.service';
import { WarrantyPaymentSuccessService } from '../../../services/warrantyPaymentSuccess.service';
import { Router } from '@angular/router';
import { CompanySearchService } from '../../../services/companySearch.service';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';
import { pipe } from 'rxjs';
import { PassUserInfoService } from '../../../services/passUserInfo.service';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';
import { AppConfigService } from '../../../app-config.service';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../common/processCancelModal/processCancelModal.component';
import { WeatherLocationFormService } from '../../../services/weatherLocationForm.service';

import jsPDF from 'jspdf';
declare var stripe: any;
declare var elements: any;

@Component({
    selector: 'app-warrantycheckout',
    templateUrl: './warrantycheckout.component.html',
    styleUrls: ['./warrantycheckout.component.scss']
})
export class WarrantycheckoutComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
    @ViewChild('cardInfo') cardInfo: ElementRef;
    bsModalRef: BsModalRef;
    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    form: FormGroup;
    message;
    messageClass;
    processing = false;
    showHidePaymentLoader: boolean;
    showHidePaymentSuccessPage: boolean;
    showHidePaymentErrorPage: boolean;
    showAllCompanyList: boolean;
    companySelected: boolean;
    allcompanyList: any[] = [];
    allCountryList: any[] = [];
    paymentSuccessOption: {} = {};
    loaderIcon: string;
    userInfo: any;
    payoutInfo: any;
    domainInfo: any;
    cancelRe: boolean;
    private sub: Subscription;
    private sub1: Subscription;
    private DomainInfosub: Subscription;
    billingsCheckboxEnabled: boolean;
    flowData: string;
    env: any;
    addr: any;
    address: string;

    constructor(
        private formBuilder: FormBuilder,
        private _location: Location,
        private modalService: BsModalService,
        private companySearch: CompanySearchService,
        private stepperStepsCompletedService: StepperStepsCompletedService,
        private warrantyPaymentSuccess: WarrantyPaymentSuccessService,
        private cd: ChangeDetectorRef,
        private passUserInfoService: PassUserInfoService,
        private passWIDInfoService: PassWIDInfoService,
        private router: Router,
        private environment: AppConfigService,
        private workflowService: WorkflowService,
        private weatherLocationFormService: WeatherLocationFormService
    ) {
        this.flowData = sessionStorage.getItem('flow');
        if (this.flowData === 'WeatherEndPurchase') {
            this.createWEForm();
            this.sub1 = this.weatherLocationFormService.locInfo$.subscribe(
                address => {
                    this.addr = address;
                    this.address = this.addr.streetName + ' ' + this.addr.cityName
                        + ', ' + this.addr.stateName + ', ' + this.addr.zip;

                });
        } else {
            this.createForm();
        }

        this.userInfo = this.passUserInfoService.getUserInfo();
        this.form.get('firstName').setValue(this.userInfo.firstName);
        this.form.get('lastName').setValue(this.userInfo.lastName);
        this.form.get('emailId').setValue(this.userInfo.emailId);
        this.sub = this.passWIDInfoService.selPayInfo$.subscribe(
            payInfo => {
                this.payoutInfo = payInfo;
            });
        this.showHidePaymentLoader = false;
        this.showHidePaymentSuccessPage = false;
        this.showHidePaymentErrorPage = false;
        this.companySelected = false;
        this.allCountryList = ['USA', 'India'];
        this.cancelRe = true;
        this.billingsCheckboxEnabled = false;
        this.env = environment.config;
    }
    createForm() {
        this.form = this.formBuilder.group({
            warrantySummaryUndertaking: [],
            emailId: ['', Validators.compose([Validators.required, Validators.email])],
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            // phone: [
            //   '',
            //   Validators.compose([
            //     Validators.required,
            //     Validators.pattern(
            //       /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ // checks for valid phone no
            //     )
            //   ])
            // ],
            companyName: ['', Validators.compose([Validators.required])],
            businessCountryName: ['Country', Validators.compose([Validators.required])],
            businessAddressName: ['', Validators.compose([Validators.required])],
            businessCityName: ['', Validators.compose([Validators.required])],
            businessStateName: ['', Validators.compose([Validators.required])],
            businessZip: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]) // checks for valid zip code

            ],
            billingCountryName: ['Country', Validators.compose([Validators.required])],
            billingAddressName: ['', Validators.compose([Validators.required])],
            billingCityName: ['', Validators.compose([Validators.required])],
            billingStateName: ['', Validators.compose([Validators.required])],
            billingZip: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(/^\d{5}(-\d{4})?$/) // checks for valid zip code
                ])
            ],
            // primaryContactEmail: [
            //   '',
            //   Validators.compose([
            //     Validators.required,
            //     Validators.minLength(5),
            //     Validators.maxLength(30),
            //     Validators.email
            //   ])
            // ],
            // primaryContactPhone: [
            //   '',
            //   Validators.compose([
            //     Validators.required,
            //     Validators.pattern(
            //       /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ // checks for valid phone no
            //     )
            //   ])
            // ],
            checkParam: new FormControl({ value: false, disabled: this.isDisabled() })
        });
    }
    createWEForm() {
        this.form = this.formBuilder.group({
            emailId: ['', Validators.compose([Validators.required, Validators.email])],
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            billingCountryName: ['Country', Validators.compose([Validators.required])],
            billingAddressName: ['', Validators.compose([Validators.required])],
            billingCityName: ['', Validators.compose([Validators.required])],
            billingStateName: ['', Validators.compose([Validators.required])],
            billingZip: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(/^\d{5}(-\d{4})?$/) // checks for valid zip code
                ])
            ],
        });
    }
    ngAfterViewInit() {
        const style = {
            base: {
                lineHeight: '24px',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#898989'
                }
            }
        };
        this.card = elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }
    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }
    onChange(status) {
        if (status.error) {
            this.error = 'Please enter a valid number';
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }

    openModalWithComponent() {
        if (this.flowData === 'WarrantyPurchase') {
            this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, {class : 'cancelModal'});
        } else if (this.flowData === 'WeatherEndPurchase') {
            const initialState = {
                cancelURL: '/',
                cancelButtonHeaderText: 'Cancel endorsement purchase',
                cancelButtonContentText: 'purchase of the endorsement?'
            };
            this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, { initialState, class : 'cancelModal' });
        }
    }

    disableForm() {
        this.form.controls['emailId'].disable();
        this.form.controls['firstName'].disable();
        this.form.controls['lastName'].disable();
        this.form.controls['companyName'].disable();
        this.form.controls['businessCountryName'].disable();
        this.form.controls['businessAddressName'].disable();
        this.form.controls['businessCityName'].disable();
        this.form.controls['businessStateName'].disable();
        this.form.controls['businessZip'].disable();
        this.form.controls['billingCountryName'].disable();
        this.form.controls['billingAddressName'].disable();
        this.form.controls['billingCityName'].disable();
        this.form.controls['billingStateName'].disable();
        this.form.controls['billingZip'].disable();
    }

    isDisabled() {
        return false;
    }

    enableForm() {
        this.form.controls['emailId'].enable();
        this.form.controls['firstName'].enable();
        this.form.controls['lastName'].enable();
        this.form.controls['companyName'].enable();
        this.form.controls['businessCountryName'].enable();
        this.form.controls['businessAddressName'].enable();
        this.form.controls['businessCityName'].enable();
        this.form.controls['businessStateName'].enable();
        this.form.controls['businessZip'].enable();
        this.form.controls['billingCountryName'].enable();
        this.form.controls['billingAddressName'].enable();
        this.form.controls['billingCityName'].enable();
        this.form.controls['billingStateName'].enable();
        this.form.controls['billingZip'].enable();
    }
    onSubmit() {
        // console.log('this.form', this.form.value);
        if (this.flowData === 'WarrantyPurchase') {
            this.processing = true;
            // this.disableForm();
            this.showHidePaymentLoader = true;
            // const { token, error } = await stripe.createToken(this.card);
            this.warrantyPaymentSuccess.WarrantyPaymentSuccessFunctionCall().subscribe(
                data => {
                    // console.log('this.showHidePaymentErrorPage', data);
                    if (data) {
                        const userInfo = {
                            firstName: '',
                            lastName: '',
                            emailId: ''
                        };
                        userInfo.firstName = this.form.get('firstName').value;
                        userInfo.lastName = this.form.get('lastName').value;
                        userInfo.emailId = this.form.get('emailId').value;
                        this.passUserInfoService.setUserInfo(userInfo);
                        if (this.form.get('companyName').value === 'John Machines Insurance.') {
                            // this.showHidePaymentErrorPage = true;
                            this.workflowService.validateStep(STEPS.checkout);
                            this.router.navigate(['/warranty/checkout/failure']);
                            this.processing = false;
                        } else {
                            this.showHidePaymentSuccessPage = true;
                        }
                        this.showHidePaymentLoader = false;
                        this.paymentSuccessOption = data.warrantySuccessContent;
                    }
                },
                (err: HttpErrorResponse) => {
                    console.log(err.message);
                }
            );
        }
        if (this.flowData === 'WeatherEndPurchase') {
            this.processing = true;
            this.showHidePaymentLoader = true;
            const userInfo = {
                firstName: '',
                lastName: '',
                emailId: ''
            };
            userInfo.firstName = this.form.get('firstName').value;
            userInfo.lastName = this.form.get('lastName').value;
            userInfo.emailId = this.form.get('emailId').value;
            this.passUserInfoService.setUserInfo(userInfo);
            this.showHidePaymentSuccessPage = true;
            this.showHidePaymentLoader = false;
        }
    }
    previousHandle() {
        if (this.flowData === 'WarrantyPurchase') {
            this.router.navigate(['/warranty/coverage']);
        } else if (this.flowData === 'WeatherEndPurchase') {
            this.router.navigate(['/weather-endorsement/payout']);
        }
    }

    /* To copy the data from the form through set field
    @ refrence
    https://toddmotto.com/angular-2-form-controls-patch-value-set-value
    */
    copyBuyerDetail() {
        if (this.form.get('checkParam').value) {
            this.form.controls['billingCountryName'].setValue(
                this.form.get('businessCountryName').value
            );
            this.form.controls['billingAddressName'].setValue(
                this.form.get('businessAddressName').value
            );
            this.form.controls['billingCityName'].setValue(
                this.form.get('businessCityName').value
            );
            this.form.controls['billingStateName'].setValue(
                this.form.get('businessStateName').value
            );
            this.form.controls['billingZip'].setValue(
                this.form.get('businessZip').value
            );
        } else {
            this.form.controls['billingCountryName'].reset('');
            this.form.controls['billingAddressName'].reset('');
            this.form.controls['billingCityName'].reset('');
            this.form.controls['billingStateName'].reset('');
            this.form.controls['billingZip'].reset('');
        }
    }
    stepperStatusChanged() {
        this.stepperStepsCompletedService.changeSecondStepperStatus(true);
    }
    selectedCompany(company) {
        this.form.controls['companyName'].setValue(
            company.companyName
        );
        this.form.controls['businessAddressName'].setValue(
            company.companyAddress
        );
        this.form.controls['businessCountryName'].setValue(
            company.companyCountry
        );
        this.form.controls['businessCityName'].setValue(
            company.companyCity
        );
        this.form.controls['businessStateName'].setValue(
            company.companyState
        );
        this.form.controls['businessZip'].setValue(
            company.companyZip
        );
        this.companySelected = true;
    }
    keypressInput(event) {
        this.companySelected = false;
    }
    cancelHandle() {
        if (this.cancelRe === true) {
            this.cancelRe = false;
        } else {
            this.cancelRe = true;
        }
    }
    ngOnInit() {
        this.stepperStatusChanged();
        if (this.flowData === 'WarrantyPurchase') {
            this.form.controls['companyName'].valueChanges
                .pipe(debounceTime(200))
                .subscribe(companySearchText => this.companySearch.getAllCompanyList().subscribe(
                    data => {
                        if (data) {
                            this.form.controls['billingAddressName'].reset('');
                            this.form.controls['billingCityName'].reset('');
                            this.form.controls['billingStateName'].reset('');
                            this.form.controls['billingZip'].reset('');
                            this.billingsCheckboxEnabled = false;
                            this.form.controls['checkParam'].setValue(false);
                            this.showAllCompanyList = true;
                            this.allcompanyList = [];
                            if (companySearchText.length > 0) {
                                for (let i = 0; i < data.allCompanyName.length; i++) {
                                    if (data.allCompanyName[i].companyName.toUpperCase().includes(companySearchText.toUpperCase())) {
                                        this.allcompanyList.push(data.allCompanyName[i]);
                                    }
                                }
                            }
                        }
                    },
                    (err: HttpErrorResponse) => {
                        console.log(err.message);
                    }
                ));
        }
        this.DomainInfosub = this.passWIDInfoService.selDomain$.subscribe(
            domain => {
                this.domainInfo = domain;
            });
    }
    selectBusinessCountry(cn) {
        this.form.get('businessCountryName').setValue(cn);
    }
    selectBillingCountry(cn) {
        this.form.get('billingCountryName').setValue(cn);
    }
    downloadPdf() {
        const doc = new jsPDF();
        doc.text('Warranty Summary', 10, 10);
        doc.save('warranty_Summary.pdf');
    }
    // ngOnChanges() {
    // console.log('ngOnChanges' , this.form.value);
    // }
    ngDoCheck() {
        // console.log('ngDoCheck' , this.form.controls['companyName'].valid, this.form.value);
        if (this.flowData === 'WarrantyPurchase') {
            this.billingsCheckboxEnabled = false;
            this.form.controls['checkParam'].disable();
            if (this.form.controls['companyName'].valid && this.form.controls['businessCountryName'].valid &&
                this.form.controls['businessAddressName'].valid && this.form.controls['businessCityName'].valid
                && this.form.controls['businessStateName'].valid && this.form.controls['businessZip'].valid) {
                this.form.controls['checkParam'].enable();
                this.billingsCheckboxEnabled = true;
            }
        }
    }
}
