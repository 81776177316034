import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanLoad, Route
} from '@angular/router';

import { WorkflowService } from './workflow.service';

@Injectable()
export class WorkflowGuard implements CanActivate {
    constructor(private router: Router, private workflowService: WorkflowService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path: string = route.routeConfig.path;
        return this.verifyWorkFlow(path);
    }

    verifyWorkFlow(path): boolean {
        console.log('Entered "' + path + '" path');

        // If any of the previous steps is invalid, go back to the first invalid step
        const firstPath = this.workflowService.getFirstInvalidStep(path);
        if (firstPath.length > 0) {
            console.log('Redirected to "' + firstPath + '"path which it is the first invalid step.');
            const flowData = sessionStorage.getItem('flow');
            let url = `/warranty/${firstPath}`;

            // url for weather endorsement based on flowdata
            if (flowData === 'WeatherEndPurchase') {
                url = `/weather-endorsement/${firstPath}`;
            }
            console.log([url], '[url]');
            this.router.navigate([url]);
            return false;
        }

        return true;
    }
}
