import { Component, OnInit } from '@angular/core';
import { Ng5SliderModule, Options, ChangeContext, PointerType } from 'ng5-slider';
import { Router } from '@angular/router';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';
import { StepperStepsCompletedService } from '../../../services/stepperStepsCompleted.service';
import { WarrantyCoverageCalc } from '../../../services/WarrantyCoverageCalc';
import { HttpErrorResponse } from '@angular/common/http';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../common/processCancelModal/processCancelModal.component';
import { AppConfigService } from '../../../app-config.service';
import { WeatherLocationFormService } from '../../../services/weatherLocationForm.service';
import { AnalysedWebsiteService } from '../../../services/getAnalysedWebsite.service';
@Component({
    selector: 'app-warranty-info-coverage',
    templateUrl: './warrantyInfoCoverage.component.html',
    styleUrls: ['./warrantyInfoCoverage.component.scss']
})
export class WarrantyInfoCoverageComponent implements OnInit {
    bsModalRef: BsModalRef;
    time = 8;
    logText = '';
    showHigherPayout = false;
    revenueRanges: any[];
    initialRevRanges: any[];
    changeSlider = false;
    selectedRevenueVal = 'Annual Revenue';
    revenueRangeVal = null;
    payoutRange: any;
    initialRange: any;
    payout: any;
    fee: any;
    NoVal = false;
    payoutOptions: Options = {};
    readToggleText = 'Read more';
    arrow = true;
    siteDetail: any;
    urlType: any;
    higherPay: boolean;
    payoutValue: any;
    duration: string;
    cancelRe: boolean;
    domainName: string;
    address: any;
    addr: any;
    sites: any;
    siteName: string;
    noUrlAlert: boolean;
    flowData: string;
    station: string;
    distance: any;
    apiData: any;
    fee_multiplier: any;
    private sub: Subscription;
    private sub1: Subscription;
    private sub2: Subscription;
    private sub3: Subscription;

    constructor(private router: Router, private modalService: BsModalService,
        private stepperStepsCompletedService: StepperStepsCompletedService,
        private warrantyCoverageCalc: WarrantyCoverageCalc, private workflowService: WorkflowService,
        private passWIDInfoService: PassWIDInfoService, private weatherLocationFormService: WeatherLocationFormService,
        public environment: AppConfigService, public analysedWebsiteService: AnalysedWebsiteService) {
        this.flowData = sessionStorage.getItem('flow');
        this.sites = [];
        this.noUrlAlert = false;
        this.siteDetail = null;
        this.siteName = null;
        this.urlType = [];
        if (this.flowData === 'WarrantyPurchase') {
            this.sub = this.passWIDInfoService.selDomain$.subscribe(
                domain => {
                    this.domainName = domain;
                    this.siteDetail = this.domainName;
                    if(this.higherPay)
                    {
                        this.higherPay = false;
                        this.resetHigherPay();
                    }
                });
            this.sub3 = this.analysedWebsiteService.apiData$.subscribe(
                apiData=>{
                    this.fee_multiplier = apiData.fee_multiplier;
                    this.calculateFee();
            });
        } else if (this.flowData === 'WeatherEndPurchase') {
            this.sub1 = this.weatherLocationFormService.locInfo$.subscribe(
                address => {
                    this.addr = address;

                    this.address = this.addr.streetName + ' ' + this.addr.cityName
                        + ', ' + this.addr.stateName + ', ' + this.addr.zip;
                });

            this.sub2 = this.weatherLocationFormService.apiInfo$.subscribe(
                apiData => {

                    this.apiData = apiData;
                    this.station = apiData['stn_name'].toLowerCase();
                    this.distance = apiData['distance'];
                    this.fee_multiplier = apiData['fee_multiplier'];
                    this.calculateFee();

                });
        }
        this.payoutValue = {};
        this.cancelRe = true;
    }
    // Triggers after the slider change is done
    onUserChangeEnd(changeContext: ChangeContext): void {
        if(this.fee_multiplier != undefined)
        {
            this.fee = (changeContext.value * this.fee_multiplier).toFixed(2);
        }
        else {
            this.fee = ((changeContext.value / 1000) * 2).toFixed(2);
        }
    }
    openModalWithComponent() {
        if (this.flowData === 'WarrantyPurchase') {
            this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, {class : 'cancelModal'});
        } else if (this.flowData === 'WeatherEndPurchase') {
            const initialState = {
                cancelURL: '/',
                cancelButtonHeaderText: 'Cancel endorsement purchase',
                cancelButtonContentText: 'purchase of the endorsement?'
            };
            this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, { initialState, class : 'cancelModal' });
        }

    }
    ngOnInit() {
        this.higherPay = false;
        this.loadInitialState();
        this.stepperStatusChanged();
        // if ( this.domainName === 'www.finfunds.com') {
        //     this.multiUrl = true;
        //     this.urlType[0] = 'HTTPS';
        //     this.sites[0] = {
        //         url: 'https://' +  this.domainName,
        //         checked: true,
        //         type: 'HTTPS'
        //     };
        //     this.sites[1] = {
        //         url: 'http://' +  this.domainName,
        //         checked: false,
        //         type: 'HTTP'
        //     };
        // }
        
    }
    calculateFee() {
        if(this.payout != undefined)
        {
            if(this.fee_multiplier != undefined)
            {
                this.fee = (this.payout * this.fee_multiplier).toFixed(2);
            }
            else {
                this.fee = ((this.payout/ 1000) * 2).toFixed(2);
            }
        }
    }

    loadInitialState() {
        if (this.flowData === 'WarrantyPurchase') {
            this.warrantyCoverageCalc.getWarrantyCoverageRange().subscribe(
                data => {
                    this.revenueRanges = this.warrantyCoverageCalc.getWarrantyRevenueRange();
                    this.initialRevRanges = this.revenueRanges;
                    this.payoutRange = data.CoverageInfo.payoutRange;
                    this.initialRange = data.CoverageInfo.payoutRange;
                    this.payout = data.CoverageInfo.payoutRange.defVal;
                    this.calculateFee();
                    this.duration = '30 days';
                    this.payoutOptions = {
                        floor: this.payoutRange.minVal,
                        ceil: this.payoutRange.maxVal,
                        step: this.payoutRange.step,
                        showSelectionBar: true,
                        autoHideLimitLabels: false,
                        showTicks: true,
                        translate: (value: number): string => {
                            return '$' + value;
                        }
                    };
                },
                (err: HttpErrorResponse) => {
                    console.log(err.message);
                });
        } else if (this.flowData === 'WeatherEndPurchase') {
            this.warrantyCoverageCalc.getWarrantyCoverageRange().subscribe(
                data => {
                    // this.revenueRanges = this.warrantyCoverageCalc.getWarrantyRevenueRange();
                    // this.initialRevRanges = this.revenueRanges;
                    this.payoutRange = data.WECoverageInfo.payoutRange;
                    this.initialRange = data.WECoverageInfo.payoutRange;
                    this.payout = data.WECoverageInfo.payoutRange.defVal;
                    this.calculateFee();
                    this.duration = '1 Year';
                    this.payoutOptions = {
                        floor: this.payoutRange.minVal,
                        ceil: this.payoutRange.maxVal,
                        step: this.payoutRange.step,
                        showSelectionBar: true,
                        autoHideLimitLabels: false,
                        showTicks: true,
                        translate: (value: number): string => {
                            return '$' + value;
                        }
                    };
                },
                (err: HttpErrorResponse) => {
                    console.log(err.message);
                });
        }

    }
    stepperStatusChanged() {
        this.stepperStepsCompletedService.changeFirstStepperStatus(true);
    }
    // selectUrls(s) {
    //     this.bothUrlAlert = false;
    //     this.noUrlAlert = false;
    //     this.NoVal = false;
    //     this.urlType = [];
    //     if (this.sites[0].checked === false && this.sites[1].checked === false ) {
    //         this.NoVal = true;
    //         this.noUrlAlert = true;
    //     } else {
    //         if (this.sites[0].checked === true && this.sites[1].checked === true ) {
    //             for (let index = 0; index < this.sites.length; index++) {
    //                 this.urlType[index] = this.sites[index].type;
    //             }
    //             this.bothUrlAlert = true;
    //         } else if (this.sites[0].checked === true) {
    //             this.urlType[0] = this.sites[0].type;
    //         } else if (this.sites[1].checked === true) {
    //             this.urlType[0] = this.sites[1].type;
    //         }
    //         this.calculateFee();
    //     }
    // }
    // Changes the slider range on dropsown selection
    changeRange(revenueRangeVal) {
        if (revenueRangeVal != null) {
            if (this.noUrlAlert === true) {
                this.NoVal = true;
            } else {
                this.NoVal = false;
            }
            this.changeSlider = true;
            this.selectedRevenueVal = revenueRangeVal.range;
            this.payoutRange = revenueRangeVal;
            this.payout = revenueRangeVal.defVal;
            this.payoutOptions = {
                floor: revenueRangeVal.minVal,
                ceil: revenueRangeVal.maxVal,
                step: revenueRangeVal.step,
                showSelectionBar: true,
                autoHideLimitLabels: false,
                showTicks: true,
                translate: (value: number): string => {
                    return '$' + value;
                }
            };
            this.calculateFee();
        }
    }

    checkHigherPayReq(higherPay) {
        if (higherPay === true) {
            this.NoVal = true;
            this.revenueRanges = this.initialRevRanges;
            if (this.revenueRangeVal != null) {
                this.changeRange(this.revenueRangeVal);
            }
        } else if (higherPay === false) {
            this.resetHigherPay();
        }
    }
    resetHigherPay() {
        this.changeSlider = false;
        if (this.noUrlAlert === true) {
            this.NoVal = true;
        } else {
            this.NoVal = false;
        }
        this.revenueRanges = [];
        this.selectedRevenueVal = 'Annual Revenue';
        this.payoutRange = this.initialRange;
        this.payout = this.payoutRange.defVal;
        this.calculateFee();
        this.payoutOptions = {
            floor: this.payoutRange.minVal,
            ceil: this.payoutRange.maxVal,
            step: this.payoutRange.step,
            showSelectionBar: true,
            autoHideLimitLabels: false,
            showTicks: true,
            translate: (value: number): string => {
                return '$' + value;
            }
        };

    }
    goToPrevious() {
        // Navigate to the webpage page
        if (this.flowData === 'WarrantyPurchase') {
            this.router.navigate(['/warranty/domain']);
        } else if (this.flowData === 'WeatherEndPurchase') {
            this.router.navigate(['/weather-endorsement/location']);
        }

    }
    goToSignUp() {
        if (this.flowData === 'WarrantyPurchase') {
            sessionStorage.setItem('flow', 'WarrantyPurchase');
            this.payoutValue.payout = this.payout;
            this.payoutValue.fee = this.fee;
            this.payoutValue.rapApplicable = false;
            if (this.payoutRange.range !== undefined) {
                this.payoutValue.rapApplicable = true;
                this.payoutValue.RAP = this.payoutRange.range;
            }
            this.payoutValue.duration = this.duration;
            this.passWIDInfoService.setPayoutInfo(this.payoutValue);
            this.passWIDInfoService.setUrlType(this.urlType);
            this.passWIDInfoService.setDomainName(this.domainName);
            this.router.navigate(['/signup']);
            this.workflowService.validateStep(STEPS.coverage);
        } else if (this.flowData === 'WeatherEndPurchase') {
            sessionStorage.setItem('flow', 'WeatherEndPurchase');
            this.payoutValue.payout = this.payout;
            this.payoutValue.fee = this.fee;
            this.payoutValue.duration = this.duration;
            this.passWIDInfoService.setPayoutInfo(this.payoutValue);
            this.passWIDInfoService.setUrlType(this.urlType);
            this.passWIDInfoService.setDomainName(this.domainName);
            this.router.navigate(['/signup']);
            this.workflowService.validateStep(STEPS.coverage);
        }
    }
    // goToSummary() {
    //     // Navigate to the sign up page
    //     this.payoutValue.payout = this.payout;
    //     this.payoutValue.fee = this.fee;
    //     this.payoutValue.rapApplicable = false;
    //     if (this.payoutRange.range !== undefined) {
    //         this.payoutValue.rapApplicable = true;
    //         this.payoutValue.RAP = this.payoutRange.range;
    //     }
    //     this.payoutValue.duration = this.duration;
    //     this.passWIDInfoService.setPayoutInfo(this.payoutValue);
    //     this.passWIDInfoService.setUrlType(this.urlType);
    //     this.passWIDInfoService.setDomainName(this.domainName);
    //     this.router.navigate(['/warranty/warrantySummary']);
    //     this.workflowService.validateStep(STEPS.coverage);
    // }
    openUrl(site) {
        window.open(site, '_blank');
    }
    // cancelHandle() {
    //     if (this.cancelRe === true) {
    //         this.cancelRe = false;
    //     } else {
    //         this.cancelRe = true;
    //     }
    // }

     goToWeather(){
        this.router.navigate(['/weather-endorsement/location']);
    }

    goToWarranty(){
        this.router.navigate(['/warranty/domain']);
    }
}
