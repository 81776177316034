import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class WarrantyPaymentSuccessService {
    constructor(private http: HttpClient) { }
    public WarrantyPaymentSuccessFunctionCall(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .get('./assets/jsonData/warrantyPaymentSuccess.json')
            .pipe(delay(2000));
    }
}
