import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appFormatNumber]'
})
export class FormatNumberDirective {
    @HostListener('input', ['$event'])
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // console.log('event target', event, event.keyCode);
        const input = event.target as HTMLInputElement;
        let keyCode = event.keyCode;
        let trimmed = input.value.replace(/\s+/g, '');
        if (trimmed.length > 10) {
            trimmed = trimmed.substr(0, 10);
        }
        if (trimmed.length === 2) {
            if (keyCode !== 8 && keyCode) {
                trimmed = trimmed.substr(0, 2) + '-' + trimmed.substr(2);
            }
        }
        input.value = trimmed;

    }
    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        let finalPastedInput: string;
        const pastedInput: string = event.clipboardData
            .getData('text/plain').trim();
        if (/^[0-9\-]+$/.test(pastedInput)) {
            // console.log('[a-zA-Z]', /^[0-9\-]+$/.test(pastedInput), pastedInput, pastedInput.trim());
            finalPastedInput = pastedInput.replace(/[^\d-]/g, '');
        } else {
            // console.log('[a-zA-Z]', /^[0-9A-Za-z]+$/.test(pastedInput), /^[a-zA-Z]+$/g.test(pastedInput));
            finalPastedInput = '';
        }
        // .replace(/[^\d-]/g, ''); // get a digit-only string
        document.execCommand('insertText', false, finalPastedInput);
    }
    // @HostListener('drop', ['$event'])
    // onDrop(event: DragEvent) {
    //   event.preventDefault();
    //   const textData = event.dataTransfer
    //     .getData('text').replace(/\D/g, '');
    //   this.inputElement.focus();
    //   document.execCommand('insertText', false, textData);
    // }

}
