import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

@Component({
    selector: 'app-term-conditions',
    templateUrl: './term-conditions.component.html',
    styleUrls: ['./term-conditions.component.scss']
})
export class TermConditionsComponent implements OnInit {
    env: any;
    constructor(private environment: AppConfigService) {
        this.env = environment.config;
    }
    ngOnInit() {
    }

}
