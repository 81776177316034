import { Component, OnInit, NgModule, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { WMWorkflowService } from '../WMWorkflow/WMWorkflow.service';
import { WMPurchaseService } from '../../../../services/wmPurchase.service';
import { STEPS } from '../WMWorkflow/WMWorkflow.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Ng5SliderModule, Options, ChangeContext, PointerType } from 'ng5-slider';
import { AppConfigService } from '../../../../app-config.service';
import { stringify } from 'querystring';
import { TouchSequence } from 'selenium-webdriver';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../../common/processCancelModal/processCancelModal.component';

@Component({
    selector: 'app-wm-purchase',
    templateUrl: './WMPurchase.component.html',
    styleUrls: ['./WMPurchase.component.scss']
})
export class WMPurchaseComponent implements OnInit {
    bsModalRef: BsModalRef;
    analysisCompletedError: boolean;
    websiteToProtect: string;
    errorPageFormHeader: string;
    smallErrorParaText: string;
    prevRouteErrorPath: string;
    thresholdOption: Options = {};
    thresholdTime: number;
    infoForm: boolean;
    env: any;
    // errAlertForm: boolean;
    // impAlertForm: boolean;
    editForm: boolean;
    // compareImpAlertForm: boolean;
    // compareErrAlertForm: boolean;
    alreadyExistAlertForm: boolean;
    compareAlreadyExistAlertForm: boolean;
    allUrls: any[];
    insertedWebsite: string;
    primaryStatus: string;
    primaryStatusCode: boolean;
    hideTextfield: boolean;
    enableCompareForm: boolean;
    hideAddMoreSiteText: boolean;
    cancelRe: boolean;
    imparedUrls: string[];
    erroredUrls: string[];
    linkStatus: string;
    noOfUrls: number;
    allowedNoOfUrl: number;
    websiteText: string;
    // cancelURL: string;
    // cancelButtonHeaderText: string;
    // cancelButtonContentText: string;
    purchaseInfo: any;
    WMInfoDomainForm = new FormGroup({
        primarywebsiteAddressInput: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('[/\\w ]*(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?')
        ])),
    });

    WMInfoCompareForm = new FormGroup({
        websiteAddressInput: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('[/\\w ]*(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?')
        ])),
    });

    constructor(private router: Router, private sanitizer: DomSanitizer, private modalService: BsModalService,
        private wmWorkflowService: WMWorkflowService, private environment: AppConfigService, private wmPurchaseService: WMPurchaseService) {
        this.websiteToProtect = '';
        this.prevRouteErrorPath = '/wm';
        this.env = this.environment.config;
        this.allUrls = [];
        this.thresholdTime = 15;
        this.editForm = true;
        this.infoForm = false;
        // this.errAlertForm = false;
        // this.impAlertForm = false;
        // this.compareImpAlertForm = false;
        // this.compareErrAlertForm = false;
        this.compareAlreadyExistAlertForm = false;
        this.alreadyExistAlertForm = false;
        this.hideTextfield = false;
        this.enableCompareForm = false;
        this.noOfUrls = 10;
        this.allowedNoOfUrl = 10;
        this.hideAddMoreSiteText = false;
        this.cancelRe = true;
        this.purchaseInfo = {};
        this.websiteText = 'websites';
        this.imparedUrls = ['www.flinfin.com', 'www.profinance.com', 'flinfin.com', 'profinance.com'];
        this.erroredUrls = ['www.funds.com', 'www.financestate.com', 'funds.com', 'financestate.com'];
        this.linkStatus = 'normal';
        this.thresholdOption = {
            floor: 5,
            ceil: 50,
            step: 5,
            showSelectionBar: true,
            autoHideLimitLabels: false,
            showTicks: true,
            translate: (value: number): string => {
                return value + ' sec';
            }
        };

    }
    ngOnInit() {
        // this.cancelURL = '/wmdetails';
        // this.cancelButtonHeaderText = 'Cancel website monitoring purchase';
        // this.cancelButtonContentText = 'purchase of the website monitoring?';
    }
    EditURLDetails() {
        this.infoForm = false;
        this.editForm = true;
        // this.errAlertForm = false;
        // this.impAlertForm = false;
        this.alreadyExistAlertForm = false;
    }
    openModalWithComponent() {
        const initialState = {
            cancelURL: '/wmdetails',
            cancelButtonHeaderText: 'Cancel website monitoring purchase',
            cancelButtonContentText: 'purchase of the website monitoring?'
        };
        this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, { initialState });
    }
    addAnotherUrl() {
        this.editForm = true;
        // this.errAlertForm = false;
        // this.impAlertForm = false;
        this.infoForm = false;
        this.alreadyExistAlertForm = false;
        this.WMInfoDomainForm.controls['primarywebsiteAddressInput'].markAsPristine();
        this.WMInfoDomainForm.setValue({ primarywebsiteAddressInput: '' });
    }
    // addUrlAnyway() {
    //     this.editForm = false;
    //     this.errAlertForm = false;
    //     this.impAlertForm = false;
    //     this.infoForm = true;
    //     this.enableCompareForm = true;
    //     this.alreadyExistAlertForm = false;
    // }
    // addCompareUrlAnyway(ipWebsite) {
    //     if (this.compareErrAlertForm === true) {
    //         if (ipWebsite !== '' && this.allUrls.length < this.allowedNoOfUrl) {
    //             const wb = {
    //                 name: '',
    //                 status: '',
    //                 statusCode: false
    //             };
    //             wb.name = ipWebsite;
    //             wb.status = 'Error Response';
    //             this.addUrlToList(wb);
    //             this.compareErrAlertForm = false;
    //             this.hideTextfield = false;
    //         }
    //     } else if (this.compareImpAlertForm === true) {
    //         if (ipWebsite !== '' && this.allUrls.length < this.allowedNoOfUrl) {
    //             const wb = {
    //                 name: '',
    //                 status: '',
    //                 statusCode: false
    //             };
    //             wb.name = ipWebsite;
    //             wb.status = 'Impaired';
    //             this.addUrlToList(wb);
    //             this.compareImpAlertForm = false;
    //             this.hideTextfield = false;
    //         }
    //     }
    // }
    addAnotherCompareUrl() {
        // if (this.compareErrAlertForm === true) {
        //     this.compareErrAlertForm = false;
        // } else if (this.compareImpAlertForm === true) {
        //     this.compareImpAlertForm = false;
        // } else

        if (this.compareAlreadyExistAlertForm === true) {
            this.compareAlreadyExistAlertForm = false;
        }
        this.hideTextfield = false;
        this.WMInfoCompareForm.setValue({ websiteAddressInput: '' });
        this.WMInfoCompareForm.controls['websiteAddressInput'].markAsPristine();
    }
    checkUrl() {
        this.WMInfoCompareForm.value.websiteAddressInput.trim();
        const analyzedWebsite = {
            name: '',
            status: '',
            statusCode: true
        };
        this.linkStatus = 'normal';
        if (this.WMInfoCompareForm.value.websiteAddressInput === this.websiteToProtect) {
            this.linkStatus = 'existing';
            this.compareAlreadyExistAlertForm = true;
            this.hideTextfield = true;
            this.insertedWebsite = this.WMInfoCompareForm.value.websiteAddressInput.toLowerCase();
        } else {
            this.allUrls.forEach(urls => {
                if (this.WMInfoCompareForm.value.websiteAddressInput === urls.name) {
                    this.linkStatus = 'existing';
                    this.compareAlreadyExistAlertForm = true;
                    this.hideTextfield = true;
                    this.insertedWebsite = this.WMInfoCompareForm.value.websiteAddressInput.toLowerCase();
                }
            });
        }
        if (this.linkStatus !== 'existing') {
            this.erroredUrls.forEach(errorUrl => {
                if (this.WMInfoCompareForm.value.websiteAddressInput === errorUrl) {
                    this.linkStatus = 'errored';
                    // this.compareErrAlertForm = true;
                    if (this.WMInfoCompareForm.value.websiteAddressInput !== '' && this.allUrls.length < this.allowedNoOfUrl) {
                        analyzedWebsite.name = this.WMInfoCompareForm.value.websiteAddressInput.toLowerCase();
                        analyzedWebsite.status = 'Error Response';
                        this.addUrlToList(analyzedWebsite);
                        this.hideTextfield = false;
                    }
                }
            });
        }
        if (this.linkStatus !== 'existing' && this.linkStatus !== 'errored') {
            this.imparedUrls.forEach(impairedUrl => {
                if (this.WMInfoCompareForm.value.websiteAddressInput === impairedUrl) {
                    this.linkStatus = 'impaired';
                    if (this.WMInfoCompareForm.value.websiteAddressInput !== '' && this.allUrls.length < this.allowedNoOfUrl) {
                        analyzedWebsite.name = this.WMInfoCompareForm.value.websiteAddressInput.toLowerCase();
                        analyzedWebsite.status = 'Impaired';
                        this.addUrlToList(analyzedWebsite);
                        this.hideTextfield = false;
                    }
                }
            });
        }
        // this.hideTextfield = true;
        // this.insertedWebsite = this.WMInfoCompareForm.value.websiteAddressInput.toLowerCase();
        if (this.linkStatus !== 'errored' && this.linkStatus !== 'impaired' && this.linkStatus !== 'existing') {
            if (this.WMInfoCompareForm.value.websiteAddressInput !== '' && this.allUrls.length < this.allowedNoOfUrl) {
                analyzedWebsite.name = this.WMInfoCompareForm.value.websiteAddressInput.toLowerCase();
                analyzedWebsite.status = 'Normal';
                this.addUrlToList(analyzedWebsite);
                this.hideTextfield = false;
            }
        }

    }
    addUrlToList(ipWebsite) {
        if (ipWebsite.name !== undefined) {
            ipWebsite.name = ipWebsite.name.trim();
        }
        this.allUrls.push(ipWebsite);
        if (this.noOfUrls >= 2) {
            this.noOfUrls--;
            if (this.noOfUrls === 1) {
                this.websiteText = 'website';
            }
            this.WMInfoCompareForm.setValue({ websiteAddressInput: '' });
            // this.WMInfoCompareForm.controls['websiteAddressInput'].setErrors(null);
            this.WMInfoCompareForm.controls['websiteAddressInput'].markAsPristine();
        } else {
            this.noOfUrls = 1;
            this.hideTextfield = true;
            this.hideAddMoreSiteText = true;
        }
    }
    removeUrlFromList(selectedUrl) {
        const urls = this.allUrls;
        for (let i = 0; i < urls.length; i++) {
            if (this.allUrls[i].name === selectedUrl) {
                urls.splice(i, 1);
                if (this.noOfUrls <= this.allowedNoOfUrl && this.noOfUrls >= 1) {
                    this.noOfUrls = this.allowedNoOfUrl - urls.length;
                    this.hideAddMoreSiteText = false;
                    this.hideTextfield = false;
                }
                if (this.compareAlreadyExistAlertForm === true) {
                    this.hideTextfield = true;
                }
            }
        }
        this.WMInfoCompareForm.setValue({ websiteAddressInput: '' });
        this.WMInfoCompareForm.controls['websiteAddressInput'].markAsPristine();
        this.allUrls = urls;
    }

    addPrimaryWebsite() {
        this.websiteToProtect = this.WMInfoDomainForm.value.primarywebsiteAddressInput.toLowerCase().trim();
        this.editForm = false;
        this.primaryStatus = 'Normal';
        this.allUrls.forEach(urls => {
            if (this.websiteToProtect === urls.name) {
                this.primaryStatus = 'Existing';
                this.alreadyExistAlertForm = true;
            }
        });
        if (this.primaryStatus !== 'Existing') {
            this.imparedUrls.forEach(impairedUrl => {
                if (this.websiteToProtect === impairedUrl) {
                    this.primaryStatus = 'Impaired';
                    // this.errAlertForm = false;
                    // this.impAlertForm = true;
                    this.infoForm = true;
                    this.enableCompareForm = true;
                }
            });
            this.erroredUrls.forEach(errorUrl => {
                if (this.websiteToProtect === errorUrl) {
                    this.primaryStatus = 'Error Response';
                    // this.errAlertForm = true;
                    // this.impAlertForm = false;
                    this.infoForm = true;
                    this.enableCompareForm = true;
                    this.insertedWebsite = this.WMInfoCompareForm.value.websiteAddressInput;
                }
            });
        }

        if (this.primaryStatus !== 'Error Response' && this.primaryStatus !== 'Impaired' && this.primaryStatus !== 'Existing') {
            this.primaryStatus = 'Normal';
            this.infoForm = true;
            this.enableCompareForm = true;
        }
    }

    onSubmit() {
        this.purchaseInfo.primaryWebsite = this.websiteToProtect.toLowerCase();
        this.purchaseInfo.additionalWebsites = this.allUrls;
        this.purchaseInfo.alertThreshold = this.thresholdTime;
        this.wmPurchaseService.setWMPurchaseInfo(this.purchaseInfo);
        this.router.navigate(['/wm/wmReview']);
        this.wmWorkflowService.validateStep(STEPS.purchase);

    }
    cancelHandle() {
        if (this.cancelRe === true) {
            this.cancelRe = false;
        } else {
            this.cancelRe = true;
        }
    }
    openUrl(site) {
        const url = 'https://' + site;
        window.open(url, '_blank');
    }

}
