// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const user = 'user';
const password = 'passwd';
const base64Converted = btoa(`${user}:${password}`);
const authToken = `Basic ${ base64Converted}`;

export const environment = {
    production: false,
    servicesBaseUrl: 'http://localhost:8080/api',
    applicationUrl: 'http://localhost:4200',
    landingPageLogo: 'assets/images/landingPage/Logo.svg',
    footerLogo: 'assets/images/detailFooter/logo-original-copy.png',
    landingPageImg: 'assets/images/landingPage/landingCover.jpg',
    marketingWIDImg: 'assets/images/marketingPage/widcover.jpg',
    loginImg: 'assets/images/loginPage/login-pg-img.jpg',
    helpIcon: 'assets/images/Common/arrow/help.svg',
    helpBigIcon: 'assets/images/Common/arrow/helpBigIcon.svg',
    notificationIcon: 'assets/images/Common/arrow/notification_pending_unselected.svg',
    logoutIcon: 'assets/images/Common/arrow/logout.svg',
    greenTickIcon: 'assets/images/Common/arrow/tick_green.svg',
    successIcon: 'assets/images/Common/arrow/success.svg',
    financeCorpLogo: 'assets/images/marketingPage/finanscorp.svg',
    bulletTickLogo: 'assets/images/marketingPage/sub_bullet.svg',
    monitorImg: 'assets/images/wmDetails/monitor_bg.jpg',
    purchaseErrAlertLogo: 'assets/images/wmPurchase/alert_red.svg',
    purchaseImpAlertLogo: 'assets/images/wmPurchase/alert_yellow.svg',
    purchaseExistAlertLogo: 'assets/images/wmPurchase/alert_blue.svg',
    purchseCrossBlueLogo: 'assets/images/wmPurchase/cross_blue.svg',
    blueTickIcon: 'assets/images/Common/arrow/tick_blue.svg',
    notificationUnselected: 'assets/images/Common/arrow/notification_unselected.svg',
    loaderIcon: './assets/images/loader_32.gif',
    backArrow: './assets/images/Common/arrow/back_arrow.svg',
    payoutBackgroundImage: './assets/images/manageWarranty/payout.svg',
    WIDBackgroundSmall: './assets/images/manageWarranty/wid_small.svg',
    WIDBackground: './assets/images/manageWarranty/wid.svg',
    bigArrowDownGreen: './assets/images/Common/arrow/big_arrow_down_green.svg',
    bigArrowDown: './assets/images/Common/arrow/big_arrow_down.svg',
    bigArrowUpGreen: './assets/images/Common/arrow/big_arrow_up_green.svg',
    bigArrowUp: './assets/images/Common/arrow/big_arrow_up.svg',
    greenTickSmallIcon: 'assets/images/Common/arrow/tick_green_small.svg',
    arrowRightLogo: 'assets/images/manageServices/arrow_right_mobile.svg',
    addMobileBtn: 'assets/images/manageServices/add_mobile.svg',
    cancelBackgroundImage: 'assets/images/manageWarranty/cancel_big.svg',
    alertRedSmall: 'assets/images/manageWarranty/alert_red_small.svg',
    cancel: 'assets/images/manageWarranty/cancel.svg',
    moneyGreen: 'assets/images/manageWarranty/money_green.svg',
    moneyYellow: 'assets/images/manageServices/money_yellow.svg',
    arrowLeftGray: 'assets/images/manageWarranty/arrow_left_grey.svg',
    viewIcon: 'assets/images/manageServices/arrow_right_mobile.svg',
    repurchaseIcon: 'assets/images/manageServices/repurchase.svg',
    widModalLogo: 'assets/images/manageServices/wid_modal.svg',
    monModalLogo: 'assets/images/manageServices/monitoring_modal.svg',
    websiteMonitoringGraphImage: 'assets/images/manageMonitoring/graph.jpg',
    activityEmpty: 'assets/images/manageMonitoring/activity_empty.svg',
    wmAnalysisIcon: 'assets/images/manageServices/magnifier_tool.svg',
    manageMonEditIcon: 'assets/images/manageMonitoring/edit.svg',
    manageMonSaveIcon: 'assets/images/manageMonitoring/save.svg',
    rocketImage: 'assets/images/manageServices/rocket.svg',
    highDownImage: 'assets/images/manageServices/high_down.svg',
    highUpImage: 'assets/images/manageServices/high_up.svg',
    mediumDown: 'assets/images/manageServices/medium_down.svg',
    doubleArrowUp: 'assets/images/manageServices/double_arrow_up.svg',
    doubleArrowDown: 'assets/images/manageServices/double_arrow_down.svg',
    cohort: 'assets/images/manageServices/cohort.svg',
    googleAnalytics: 'assets/images/manageServices/GAnalytics.svg',
    googlePlus: 'assets/images/manageServices/GPlus.svg',
    handlebars: 'assets/images/manageServices/Handlebars.svg',
    jquery: 'assets/images/manageServices/jQuery.svg',
    mathjax: 'assets/images/manageServices/Mathjax.svg',
    nginx: 'assets/images/manageServices/Nginx.svg',
    react: 'assets/images/manageServices/React.svg',
    webpack: 'assets/images/manageServices/webpack.svg',
    download: 'assets/images/manageServices/download.svg',
    crossProfileIcon: 'assets/images/manageProfile/cross_black.svg',
    noNotification: 'assets/images/Common/arrow/no_notification.svg',
    infoLogo: 'assets/images/Common/arrow/info.svg',
    showLogo: 'assets/images/Common/arrow/show.svg',
    hideLogo: 'assets/images/Common/arrow/hide.svg',
    dropdownArrow: 'assets/images/Common/arrow/arrow_down.svg',
    dropdownUpArrow: 'assets/images/Common/arrow/arrow_up.svg',
    terminatedIcon: 'assets/images/manageServices/terminated.svg',
    analysisAlert: 'assets/images/WIDPurchase/analysis_alert.svg',
    analysisError: 'assets/images/WIDPurchase/analysis_error.svg',
    analysisLogin: 'assets/images/WIDPurchase/analysis_login.svg',
    analysisSearch: 'assets/images/WIDPurchase/analysis_search.svg',
    analysisSuccess: 'assets/images/WIDPurchase/analysis_success.svg',
    emptyAboutusImage: 'assets/images/Common/about.svg',
    emptyContactusImage: 'assets/images/Common/contact.svg',
    emptyprivacyPolicyImage: 'assets/images/Common/pp.svg',
    emptyTermsConditionImage: 'assets/images/Common/tnc.svg',
    cancelWarranty: 'assets/images/manageWarranty/cancel_warranty.svg',
    separator: 'assets/images/loginPage/separator.svg',
    mcLogoBig: 'assets/images/loginPage/mc_logo_big.png',
    warrantyDocumentPdf: 'assets/WarrantyDocument.pdf',
    receiptPdf: 'assets/receipt.pdf',
    draftPdf: 'assets/warrantyDraft.pdf',
    paymentFailureIcon: 'assets/images/WIDPurchase/card_error.svg',
    locationIcon: 'assets/images/manageEndorsement/location.svg',
    weatherIcon: 'assets/images/manageEndorsement/weather.svg',
    windIcon: 'assets/images/manageEndorsement/wind.svg',
    graphImg: 'assets/images/manageEndorsement/Graph.svg',
    tempUnit: 'assets/images/manageEndorsement/temp_unit.svg',
    windTableImg: 'assets/images/manageEndorsement/table.svg',
    windDamageImg: 'assets/images/weatherDetails/damage.svg',
    winddetailsImg: 'assets/images/weatherDetails/wind_Details.svg',
    windSpeedImg: 'assets/images/weatherDetails/windspeed.svg',
    authToken: authToken,
    apiUrl : 'https://stormlock.machinecover.com/api/storm-lock/predictions',
    widUrl: 'https://wid.machinecover.com/api/widapi/quotes',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
