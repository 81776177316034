import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './pages/main/main.component';
import { LandingComponent } from './pages/landing/landing.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { SignUpComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { WarrantyDetailMainComponent } from './pages/warrantyDetails/warranty-detail-main/warranty-detail-main.component';
import { WebpageUrlNotFoundComponent } from './pages/common/webpageNotFound/webpageUrlNotFound.component';
import { ExistingWarrantyComponent } from './pages/existingWarranty/existingWarranty.component';
import { SelectWarrantyComponent } from './pages/selectWarranty/selectWarranty.component';
import { ManageProfileComponent } from './pages/manageProfile/manageProfile.component';
import { ProfileNotificationsComponent } from './pages/profileNotifications/profileNotifications.component';
import { WarrantySettingDetailComponent } from './pages/warrantyDetails/warranty-setting-detail/warranty-setting-detail.component';
import { WMDetailsComponent } from './pages/websiteMonitoring/WMDetails/WMDetails.component';
import { EmailVerificationComponent } from './pages/emailVerification/emailVerify.component';
import { ManageWarrantyMainComponent } from './pages/manageWarranty/manage-warranty-main/manage-warranty-main.component';
import { ManageServiceComponent } from './pages/manageService/manageService.component';
import { MonitorMainComponent } from './pages/manageMonitoring/monitor-main/monitor-main.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermConditionsComponent } from './pages/term-conditions/term-conditions.component';
import { ForgotPasswordComponent } from './pages/forgotPassword/forgot-password.component';
import { CreateNewPasswordComponent } from './pages/createNewPassword/create-new-password.component';
import { ManageEndorsementMainComponent } from './pages/manageEndorsement/manage-endorsement-main/manage-endorsement-main.component';
import { WeatherDetailComponent } from './pages/weatherDetail/weather-detail.component';
import { StormLockLandingPageComponent } from './pages/storm-lock-landing-page/storm-lock-landing-page.component';
const routes: Routes = [
    { path: '', component: MainComponent },
    { path: 'widdetails', component: MarketingComponent },
    { path: 'weatherdetails', component:  StormLockLandingPageComponent},
    { path: 'signup', component: SignUpComponent },
    { path: 'login', component: LoginComponent },
    { path: 'warranty-detail', component: WarrantyDetailMainComponent },
    { path: 'warranty', redirectTo: '/warranty/domain' },
    { path: 'weather-endorsement', redirectTo: '/weather-endorsement/location' },
    { path: 'verify', component: EmailVerificationComponent },
    { path: 'wmdetails', component: WMDetailsComponent },
    { path: 'select-warranty', component: SelectWarrantyComponent },
    { path: 'existingWarranty', component: ExistingWarrantyComponent },
    { path: 'manage-profile', component: ManageProfileComponent },
    { path: 'notifications', component: ProfileNotificationsComponent },
    { path: 'change-details', component: WarrantySettingDetailComponent },
    { path: 'manage-warranty', component: ManageWarrantyMainComponent },
    { path: 'manage-monitor', component: MonitorMainComponent },
    { path: 'manage-endorsement', component: ManageEndorsementMainComponent },
    { path: 'manage-service', component: ManageServiceComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'term-conditions', component: TermConditionsComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'create-new-password', component: CreateNewPasswordComponent },
    { path: '**', component: WebpageUrlNotFoundComponent }
];

@NgModule({
    declarations: [StormLockLandingPageComponent],
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    providers: [],
    bootstrap: []
})
export class AppRoutingModule { }
