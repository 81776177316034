import { Injectable } from '@angular/core';
import {
    HttpClient
} from '@angular/common/http';

@Injectable()
export class PassUserInfoService {
    userInfo: any;
    payoutInfo: any;
    constructor(private http: HttpClient) { }
    setUserInfo(user) {
        this.userInfo = user;
    }
    setPayoutInfo(pay) {
        this.payoutInfo = pay;
    }
    getUserInfo() {
        return this.userInfo;
    }
    getPayoutInfo() {
        return this.payoutInfo;
    }

}
