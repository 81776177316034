import { Component, OnInit } from '@angular/core';
import { WarrantyHistoryDetailService } from '../../../services/warrantyHistoryDetail.service';
import * as moment from 'moment';

@Component({
    selector: 'app-warranty-history',
    templateUrl: './warranty-history.component.html',
    styleUrls: ['./warranty-history.component.scss']
})
export class WarrantyHistoryComponent implements OnInit {

    warrantyHistoryData: any[];

    constructor(private warrantyHistoryDetailService: WarrantyHistoryDetailService) { }
    ngOnInit() {
        this.getWarrantyDetail();
    }
    getWarrantyDetail() {
        this.warrantyHistoryDetailService.WarrantyHistoryDetailData().subscribe(data => {
            this.warrantyHistoryData = data.warrantyHistoryData;
        });
    }
    historyDateConverter(date) {
        return `${moment(date).format('MMMM D, YYYY')}`;
    }
    receiptDownload() {
        console.log('receipt will download');
    }

}
