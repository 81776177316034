import { Component, OnInit, NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HeaderRiskDropdown } from '../../../services/headerRiskDropdown.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../../app-config.service';
import { Router } from '@angular/router';

@NgModule({
    imports: [BsDropdownModule.forRoot(), TooltipModule.forRoot()]
})
@Component({
    selector: 'app-header-navigation-bar',
    templateUrl: './header-navigation-bar.component.html',
    styleUrls: ['./header-navigation-bar.component.scss']
})
export class HeaderNavigationBarComponent implements OnInit {
    logoPath: string;
    list: any[];
    onlineRiskList: any[];
    regulatoryRiskList: any[];
    constructor(
        public headerRiskDropdown: HeaderRiskDropdown,
        private environment: AppConfigService,
        private router: Router
    ) {
        this.onlineRiskList = [];
        this.regulatoryRiskList = [];
    }

    ngOnInit() {
        this.logoPath = this.environment.config.landingPageLogo;
        this.headerRiskDropdown.getWarrantyList().subscribe(
            data => {
                if (data.onlineRisks) {
                    for (let i = 0; i < data.onlineRisks.length; i++) {
                        this.onlineRiskList.push(data.onlineRisks[i]);
                    }
                }
                if (data.regulatoryRisks) {
                    for (let j = 0; j < data.regulatoryRisks.length; j++) {
                        this.regulatoryRiskList.push(data.regulatoryRisks[j]);
                    }
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            }
        );
    }
    goToSignUp() {
        // Navigate to the sign up page
        sessionStorage.setItem('flow', 'LandingPageSignUp');
        this.router.navigate(['/signup']);
    }
    goToLogin() {
        this.router.navigate(['/login']);
    }
}
