import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WMPurchaseService } from '../../../../services/wmPurchase.service';
import { WMWorkflowService } from '../WMWorkflow/WMWorkflow.service';
import { STEPS } from '../WMWorkflow/WMWorkflow.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../../common/processCancelModal/processCancelModal.component';
@Component({
    selector: 'app-wmreview',
    templateUrl: './wmreview.component.html',
    styleUrls: ['./wmreview.component.scss']
})
export class WMReviewComponent implements OnInit {
    bsModalRef: BsModalRef;
    additionalWebsites: any;
    purchaseInfo: any;
    modalCancelUrl: string;
    modalCancelHeaderText: string;
    modalCancelContentText: string;
    cancelRe: boolean;

    constructor(private router: Router, private wmPurchaseService: WMPurchaseService, private modalService: BsModalService,
        private wmWorkflowService: WMWorkflowService) {
        this.modalCancelUrl = '/wmdetails';
        this.modalCancelHeaderText = 'Cancel website monitoring purchase';
        this.modalCancelContentText = 'purchase of the website monitoring?';
        this.cancelRe = true;
    }

    ngOnInit() {
        this.purchaseInfo = this.wmPurchaseService.getWMPurchaseInfo();
    }
    goToSignUp() {
        sessionStorage.setItem('flow', 'WebMonitoringPurchase');
        this.router.navigate(['/signup']);
        this.wmWorkflowService.validateStep(STEPS.review);
    }
    goToPrevious() {
        this.router.navigate(['/wm/purchase']);
    }
    openUrl(site) {
        const url = 'https://' + site;
        window.open(url, '_blank');
    }
    openModalWithComponent() {
        const initialState = {
            cancelURL: '/wmdetails',
            cancelButtonHeaderText: 'Cancel website monitoring purchase',
            cancelButtonContentText: 'purchase of the website monitoring?'
        };
        this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, { initialState });
    }
}
