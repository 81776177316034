import { Component, OnInit } from '@angular/core';
import { WarrantyDetailTabService } from '../../../services/warrantyDetailTab.service';
@Component({
    selector: 'app-warranty-detail',
    templateUrl: './warranty-detail.component.html',
    styleUrls: ['./warranty-detail.component.scss']
})
export class WarrantyDetailComponent implements OnInit {
    warrantyDetailConditions: any[];
    warrantyDetailFeeConditions: any[];
    constructor(private warrantyDetailTabService: WarrantyDetailTabService) { }

    getWarrantyDetailTabData() {
        this.warrantyDetailTabService.WarrantyDetailTabData().subscribe(data => {
            this.warrantyDetailConditions = data.allWarrantyDetail.allWarrantyConditions;
            this.warrantyDetailFeeConditions = data.allWarrantyDetail.warrantyFeeConditions;
        });
    }

    ngOnInit() {
        this.getWarrantyDetailTabData();
    }

}
