import { Component, OnInit } from '@angular/core';
import { WarrantySummaryService } from '../../../services/warrantySummary.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../common/processCancelModal/processCancelModal.component';

@Component({
    selector: 'app-warranty-summary',
    templateUrl: './warrantySummary.component.html',
    styleUrls: ['./warrantySummary.component.scss']
})
export class WarrantySummaryComponent implements OnInit {
    bsModalRef: BsModalRef;
    warrantyConditions: any[] = [];
    payoutInfo: any;
    domainInfo: any;
    isCollapsedUserInfo: boolean;
    cancelRe: boolean;
    multiUrl: boolean;
    urlType: any;
    private sub1: Subscription;
    private sub2: Subscription;
    private sub3: Subscription;

    userAdditionalInfoForm = new FormGroup({
        warrantySummaryUndertaking: new FormControl('')
    });
    userInfoInputNumbers = new FormGroup({
        EINSNumber: new FormControl(''),
        DUNSNumber: new FormControl('')
    });

    constructor(public warrantySummaryService: WarrantySummaryService, private router: Router, private modalService: BsModalService,
        private workflowService: WorkflowService, private passWIDInfoService: PassWIDInfoService) {
        this.isCollapsedUserInfo = true;
        this.cancelRe = true;
        this.multiUrl = false;
    }
    ngOnInit() {
        this.sub1 = this.passWIDInfoService.selDomain$.subscribe(
            domain => {
                this.domainInfo = 'https://' + domain;
            });
        this.sub2 = this.passWIDInfoService.selPayInfo$.subscribe(
            payinfo => {
                this.payoutInfo = payinfo;
            });
        this.sub3 = this.passWIDInfoService.selUrlType$.subscribe(
            ut => {
                this.urlType = ut;
                if (this.urlType.length === 2) {
                    this.multiUrl = true;
                }
            });
        this.warrantySummaryService.WarrantySummaryServiceCall().subscribe(
            data => {
                if (data) {
                    this.warrantyConditions = data.allWarrantyCondition;
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
    }
    goToPrevious() {
        // Navigate to the domain page
        this.router.navigate(['/warranty/coverage']);

    }
    onSubmit() {
    }
    gotoPurchase() {
        // this.workflowService.validateStep(STEPS.warrantySummary);
        this.router.navigate(['/warranty/checkout']);
    }
    goToSignUp() {
        sessionStorage.setItem('flow', 'WarrantyPurchase');
        // this.workflowService.validateStep(STEPS.warrantySummary);
        this.router.navigate(['/signup']);
    }
    openUrl(site) {
        let s = 'https://';
        if (this.urlType[0] === 'HTTPS') {
            s = 'https://' + site;
        } else {
            s = 'http://' + site;
        }
        window.open(s, '_blank');
    }
    openModalWithComponent() {
        this.bsModalRef = this.modalService.show(ProcessCancelModalComponent);
    }
}
