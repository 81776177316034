import {
    Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild,
    ElementRef
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { PassUserInfoService } from '../../../../services/passUserInfo.service';
import { WarrantyPaymentSuccessService } from '../../../../services/warrantyPaymentSuccess.service';
import { WmStepsCompletedService } from '../../../../services/wmServiceStepsComplete.service';
import { AppConfigService } from '../../../../app-config.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProcessCancelModalComponent } from '../../../common/processCancelModal/processCancelModal.component';
declare var stripe: any;
declare var elements: any;

@Component({
    selector: 'app-review-checkout-form',
    templateUrl: './review-checkout-form.component.html',
    styleUrls: ['./review-checkout-form.component.scss']
})
export class ReviewCheckoutFormComponent implements OnInit, AfterViewInit, OnDestroy {
    bsModalRef: BsModalRef;
    @ViewChild('cardInfo') cardInfo: ElementRef;
    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    form: FormGroup;
    message;
    messageClass;
    processing = false;
    showHidePaymentLoader: boolean;
    showHidePaymentSuccessPage: boolean;
    allCountryList: any[] = [];
    paymentSuccessOption: {} = {};
    userInfo: any;
    modalCancelUrl: string;
    modalCancelHeaderText: string;
    modalCancelContentText: string;
    env: any;
    cancelRe: boolean;
    constructor(
        private formBuilder: FormBuilder,
        private _location: Location,
        private cd: ChangeDetectorRef,
        private router: Router,
        private modalService: BsModalService,
        private passUserInfoService: PassUserInfoService,
        private warrantyPaymentSuccess: WarrantyPaymentSuccessService,
        private wmStepsCompletedService: WmStepsCompletedService,
        private environment: AppConfigService
    ) {
        this.createForm();
        this.env = environment.config;
        this.userInfo = this.passUserInfoService.getUserInfo();
        this.form.get('firstName').setValue(this.userInfo.firstName);
        this.form.get('lastName').setValue(this.userInfo.lastName);
        this.form.get('emailId').setValue(this.userInfo.emailId);
        this.showHidePaymentLoader = false;
        this.showHidePaymentSuccessPage = false;
        this.allCountryList = ['USA', 'India'];
        this.modalCancelUrl = '/wmdetails';
        this.modalCancelHeaderText = 'Cancel website monitoring purchase';
        this.modalCancelContentText = 'purchase of the website monitoring?';
        this.cancelRe = true;
    }
    createForm() {
        this.form = this.formBuilder.group({
            warrantySummaryUndertaking: [],
            emailId: ['', Validators.compose([Validators.required, Validators.email])],
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            billingCountryName: ['Country', Validators.compose([Validators.required])],
            billingAddressName: ['', Validators.compose([Validators.required])],
            billingCityName: ['', Validators.compose([Validators.required])],
            billingStateName: ['', Validators.compose([Validators.required])],
            billingZip: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(/^\d{5}(-\d{4})?$/) // chacks for valid zip code
                ])
            ],
        });
    }
    ngAfterViewInit() {
        const style = {
            base: {
                lineHeight: '24px',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#898989'
                }
            }
        };
        this.card = elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }
    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }
    onChange(status) {
        if (status.error) {
            this.error = 'Please enter a valid number';
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }
    openModalWithComponent() {
        const initialState = {
            cancelURL: '/wmdetails',
            cancelButtonHeaderText: 'Cancel website monitoring purchase',
            cancelButtonContentText: 'purchase of the website monitoring?'
        };
        this.bsModalRef = this.modalService.show(ProcessCancelModalComponent, { initialState });
    }

    disableForm() {
        this.form.controls['emailId'].disable();
        this.form.controls['firstName'].disable();
        this.form.controls['lastName'].disable();
        this.form.controls['billingCountryName'].disable();
        this.form.controls['billingAddressName'].disable();
        this.form.controls['billingCityName'].disable();
        this.form.controls['billingStateName'].disable();
        this.form.controls['billingZip'].disable();
    }

    isDisabled() {
        return false;
    }

    enableForm() {
        this.form.controls['emailId'].enable();
        this.form.controls['firstName'].enable();
        this.form.controls['lastName'].enable();
        this.form.controls['billingCountryName'].enable();
        this.form.controls['billingAddressName'].enable();
        this.form.controls['billingCityName'].enable();
        this.form.controls['billingStateName'].enable();
        this.form.controls['billingZip'].enable();
    }
    onSubmit() {
        this.processing = true;
        this.disableForm();
        this.showHidePaymentLoader = true;
        this.warrantyPaymentSuccess.WarrantyPaymentSuccessFunctionCall().subscribe(
            data => {
                if (data) {
                    const userInfo = {
                        firstName: '',
                        lastName: '',
                        emailId: ''
                    };
                    userInfo.firstName = this.form.get('firstName').value;
                    userInfo.lastName = this.form.get('lastName').value;
                    userInfo.emailId = this.form.get('emailId').value;
                    this.passUserInfoService.setUserInfo(userInfo);
                    this.showHidePaymentLoader = false;
                    this.showHidePaymentSuccessPage = true;
                    this.paymentSuccessOption = data.warrantySuccessContent;
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            }
        );
    }
    previousHandle() {
        this.router.navigate(['/wm/wmReview']);
    }

    cancelHandle() {
        if (this.cancelRe === true) {
            this.cancelRe = false;
        } else {
            this.cancelRe = true;
        }
    }
    ngOnInit() {
        this.stepperStatusChanged();
    }
    stepperStatusChanged() {
        this.wmStepsCompletedService.changeFirstStepperStatus(true);
    }
    selectBillingCountry(cn) {
        this.form.get('billingCountryName').setValue(cn);
    }
}

