import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class WebsiteWarrantyService {
    constructor(private http: HttpClient) { }
    createHeader() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }

    public WebsiteWarrantyFunctionCall(): Observable<any> {
        this.createHeader();
        return this.http
            .get('./assets/jsonData/warrantyWebsite.json')
            .pipe(delay(2000));
    }

    getWarrentyDetails(): Observable<any> {
        this.createHeader();
        return this.http.get('./assets/jsonData/warrantyDetail.json');
    }

    getActivityDetails(): Observable<any> {
        this.createHeader();
        return this.http.get('./assets/jsonData/activityDetail.json').pipe(delay(2000));
    }

}
