import { Component, OnInit } from '@angular/core';
import { WeatherDetail } from '../../services/weatherDetail.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-weather-detail',
  templateUrl: './weather-detail.component.html',
  styleUrls: ['./weather-detail.component.scss']
})
export class WeatherDetailComponent implements OnInit {

  env: any;
    domainPath: string;
    constructor(private weatherDetail: WeatherDetail, private environment: AppConfigService) {
        this.env = environment.config;
        this.domainPath = '/weather-endorsement/location';
    }
    weatherInfo: any;
    steps: string[] = [];
    businessImpact: string[] = [];
    workStructure: string[] = [];
    title: string;
    slogan: string;
    landingPageCover: string;
  ngOnInit() {
    sessionStorage.setItem('flow', 'WeatherEndPurchase');
    this.weatherDetail.getWeatherDetail().subscribe(
      data => {
          this.weatherInfo = data.weatherInfo[0];
          this.steps = data.weatherInfo[0].content.steps;
          this.businessImpact = data.weatherInfo[0].content.businessImpact;
      },
      (err: HttpErrorResponse) => {
          console.log(err.message);
      });
  }

}
