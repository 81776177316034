import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UploadModalComponent } from '../upload-modal/upload-modal.component';
import { UtilityService } from './../../../services/utility.service';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';

@Component({
    selector: 'app-user-additional-information',
    templateUrl: './userAdditionalInformation.component.html',
    styleUrls: ['./userAdditionalInformation.component.scss']
})

export class UserAdditionalInfoComponent implements OnInit {
    modalRef: BsModalRef;
    logsDocumentUploaded: boolean;
    documentUploaded: boolean;
    websiteVerified: boolean;
    fileData: string;
    logFileData: string;
    greenTickSmallIcon: string;
    einsNumberConfirmed: boolean;
    payoutPendingCheck: boolean;

    @Input()
    ownershipWebsiteUrl: string;
    userInfoInputNumber = new FormGroup({
        EINSNumber: new FormControl('', [Validators.required, Validators.minLength(10)
        ]
        ),
    });
    constructor(private modalService: BsModalService,
        private environment: AppConfigService,
        private manageService: ManageDetailsService,
        private util: UtilityService) {
        this.documentUploaded = false;
        this.logsDocumentUploaded = false;
        this.websiteVerified = false;
        this.greenTickSmallIcon = environment.config.greenTickSmallIcon;
        this.einsNumberConfirmed = false;
        this.payoutPendingCheck = false;
    }

    ngOnInit() {
    }
    verifyWebsite() {
        this.websiteVerified = true;
    }
    completeBankAccout() {
        this.manageService.setNewTab(2);
    }
    uploadDocument() {
        this.openModal().subscribe(data => {
            console.log('filedata', typeof (data), data, 'this.documentUploaded', this.documentUploaded);
            this.fileData = data;
            this.documentUploaded = true;
        });
    }
    uploadLogDocument() {
        this.openModal().subscribe(data => {
            this.logFileData = data;
            this.logsDocumentUploaded = true;
        });
    }
    onSubmit() {
        this.einsNumberConfirmed = true;
        this.userInfoInputNumber.controls['EINSNumber'].disable();
    }
    editEINSNumberInput() {
        this.einsNumberConfirmed = false;
        this.userInfoInputNumber.controls['EINSNumber'].enable();
    }
    convertToMB(fileSize) {
        return Number(fileSize / 1024 / 1024).toFixed(3);
    }
    openModal() {
        this.modalRef = this.modalService.show(
            UploadModalComponent,
            Object.assign({}, { class: 'gray modal-lg' })
        );
        return new Observable<string>(this.getConfirmSubscriber());
    }

    private getConfirmSubscriber() {
        return observer => {
            const subscription = this.modalService.onHidden.subscribe(
                (reason: string) => {
                    observer.next(this.modalRef.content.answer);
                    observer.complete();
                }
            );

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                }
            };
        };
    }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 45 || charCode > 57 || (charCode > 45 && charCode < 48))) {
            return false;
        }
        return true;

    }
}
