import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WMInfoParentComponent } from './WMInfoParent/WMInfoParent.component';

import { WMPurchaseComponent } from './WMPurchase/WMPurchase.component';
import { WMWorkflowGuard } from './WMWorkflow/WMWorkflow-guard.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './WMrouterReuseStrategy';
import { ReviewCheckoutFormComponent } from './review-checkout-form/review-checkout-form.component';
import { WMReviewComponent } from './WMReview/wmreview.component';
import { EmailVerificationComponent } from '../../emailVerification/emailVerify.component';
const secondaryRoutes: Routes = [
    {
        path: 'wm', // <---- parent component declared here
        component: WMInfoParentComponent,
        children: [
            // <---- child components declared here
            {
                path: 'purchase',
                component: WMPurchaseComponent,
                canActivate: [WMWorkflowGuard]
            },
            { path: 'wmReview', component: WMReviewComponent, canActivate: [WMWorkflowGuard] },
            { path: 'wmCheckout', component: ReviewCheckoutFormComponent, canActivate: [WMWorkflowGuard] },
            {
                path: 'verify',
                component: EmailVerificationComponent,
            },
            { path: '', redirectTo: '/wm/purchase', pathMatch: 'full' }
        ]
    }
];
// taken from angular.io
// Only call RouterModule.forRoot in the root AppRoutingModule (or the AppModule if
// that's where you register top level application routes). In any other module, you
// must call the RouterModule.forChild method to register additional routes.
@NgModule({
    imports: [RouterModule.forChild(secondaryRoutes)],
    exports: [RouterModule],
    providers: [
        WMWorkflowGuard,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ]
})
export class WMInfoRoutingModule { }
