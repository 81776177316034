import { Component, OnInit, ViewChild, TemplateRef, AfterContentInit } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-monitor-main',
    templateUrl: './monitor-main.component.html',
    styleUrls: ['./monitor-main.component.scss']
})
export class MonitorMainComponent implements OnInit {
    private serviceDetailSubscription: Subscription;
    env: any;
    serviceDetail: any = {};
    payoutPendingState: boolean;
    tabNumber: number;
    @ViewChild('staticTabs')
    staticTabs: TabsetComponent;

    constructor(private environment: AppConfigService, private router: Router, private manageDetails: ManageDetailsService
    ) {
        this.env = environment.config;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.serviceDetail = service;
            });
    }
    ngOnInit() {
        this.serviceDetailSubscription = this.manageDetails.changeTab.subscribe(
            tabIDs => {
                this.tabNumber = tabIDs;
                this.staticTabs.tabs[this.tabNumber].active = true;
            });
    }
}
