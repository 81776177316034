import { Component, OnInit, NgModule } from '@angular/core';
import { StepperStepsCompletedService } from '../../../services/stepperStepsCompleted.service';
import { AppConfigService } from '../../../app-config.service';
@Component({
    selector: 'app-warranty-info-navbar',
    templateUrl: './warrantyInfoNavbar.component.html',
    styleUrls: ['./warrantyInfoNavbar.component.scss']
})
export class WarrantyInfoNavbarComponent implements OnInit {
    firstStepCompleted: boolean;
    secondStepCompleted: boolean;
    thirdStepCompleted: boolean;
    env: any;
    flowData: string;
    constructor(private stepperStepsCompletedService: StepperStepsCompletedService, private environment: AppConfigService) {
        this.firstStepCompleted = false;
        this.secondStepCompleted = false;
        this.thirdStepCompleted = false;
        this.env = environment.config;
        this.flowData = sessionStorage.getItem('flow');
    }

    ngOnInit() {
        this.stepperStepsCompletedService.currentFirstStepStatus.subscribe(
            firstStepCompleted => this.firstStepCompleted = firstStepCompleted);
        this.stepperStepsCompletedService.currentSecondStepStatus.subscribe(
            secondStepCompleted => this.secondStepCompleted = secondStepCompleted);
        this.stepperStepsCompletedService.currentThirdStepStatus.subscribe(
            thirdStepCompleted => this.thirdStepCompleted = thirdStepCompleted);
    }

}
