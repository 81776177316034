import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
    selector: 'app-upload-modal',
    templateUrl: './upload-modal.component.html',
    styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
    public uploader: FileUploader = new FileUploader({ url: URL });
    public hasBaseDropZoneOver = false;
    public hasAnotherDropZoneOver = false;
    responseData: string;
    editText = false;
    answer;
    constructor(public bsModalRef: BsModalRef) {
        this.uploader.response.subscribe(res => { this.responseData = res; },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            });
    }

    ngOnInit() { }

    respond() {
        this.answer = this.uploader.queue;
        this.bsModalRef.hide();
    }
    convertToMB(fileSize) {
        return Number(fileSize / 1024 / 1024).toFixed(3);
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    isEditing() {
        if (this.editText) {
            this.editText = false;
        } else {
            this.editText = true;
        }
    }
}
