import { Component, OnInit } from '@angular/core';
import { AllRiskDetailService } from '../../services/allRiskDetails.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PageScrollInstance, PageScrollService, EasingLogic } from 'ngx-page-scroll';
import { AppConfigService } from '../../app-config.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
    env: any;

    public warrantyEasing: EasingLogic = {
        ease: (t: number, b: number, c: number, d: number): number => {
            // easeInOutExpo easing
            if (t === 0) {
                return b;
            }
            if (t === d) {
                return b + c;
            }
            if ((t /= d / 2) < 1) {
                return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
            }
            return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
        }
    };

    keys(obj) {
        return Object.keys(obj);
    }
    constructor(
        public allRiskDetailService: AllRiskDetailService,
        private pageScrollService: PageScrollService,
        private environment: AppConfigService
    ) {
        this.env = environment.config;
    }

    ngOnInit() {
    }
}
