import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';

import { PageScrollService } from 'ngx-page-scroll';

@Component({
    selector: 'app-endorse-summary',
    templateUrl: './endorse-summary.component.html',
    styleUrls: ['./endorse-summary.component.scss']
})
export class EndorseSummaryComponent implements OnInit, OnDestroy {
    private serviceDetailSubscription: Subscription;
    isCollapsedUserInfo: boolean;
    showActiveHourstext: boolean;
    selectedService: any = {};
    payoutPendingState: boolean;
    showMassOutageText: boolean;
    env: any;
    //   userInfoInputNumbers = new FormGroup({
    //     EINSNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)
    //     ]
    // ),
    // });

    constructor(private router: Router, private pageScrollService: PageScrollService, private environment: AppConfigService,
        private manageDetails: ManageDetailsService, private passWIDInfoService: PassWIDInfoService) {
        this.isCollapsedUserInfo = true;
        this.env = environment.config;
        this.showActiveHourstext = false;
        this.showMassOutageText = false;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
            });
        this.serviceDetailSubscription = this.manageDetails.payoutPending.subscribe(
            service => {
                this.payoutPendingState = service;
            });
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        this.serviceDetailSubscription.unsubscribe();
    }
    openUrl(service) {
        const site = service.website;
        window.open(site, '_blank');
    }
}
