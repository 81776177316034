import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AllRiskDetailService {

    constructor(private http: HttpClient) {
    }

    public allRiskDetailsList(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get('./assets/jsonData/allRiskDetails.json');

    }
}
