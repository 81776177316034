import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class AnalysedWebsiteService {
    
    private apiData = new BehaviorSubject<any>(null);
    
    public apiData$ = this.apiData.asObservable();
    
    constructor(private http: HttpClient, private environment: AppConfigService) { }
    
    public analysedWebsiteList(url): Observable<any> {
        let headers = new HttpHeaders();
        
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        headers = headers.set('Authorization', this.environment.config.authToken);

        return this.http
            .get(this.environment.config.widUrl,{
                params: {
                    url: url
                },
                headers: headers
            });   
    }
    
    setData(data) {
        this.apiData.next(data);
    }
}
