import {
    Component,
    ViewChild,
    NgModule,
    TemplateRef,
    Input
} from '@angular/core';
import {
    BsModalService,
    BsModalRef,
    ModalModule,
    ModalDirective
} from 'ngx-bootstrap';
import { Router } from '@angular/router';

@NgModule({
    imports: [ModalModule.forRoot()]
})
@Component({
    selector: 'app-process-cancel-modal',
    templateUrl: './processCancelModal.component.html',
    styleUrls: ['./processCancelModal.component.scss']
})
export class ProcessCancelModalComponent {
    modalRef: BsModalRef;
    @Input()
    cancelButtonHeaderText: string;
    @Input()
    cancelButtonContentText: string;
    @Input()
    cancelURL: string;
    isModalShown = false;

    @ViewChild('autoShownModal')
    autoShownModal: ModalDirective;
    constructor(private modalService: BsModalService, private router: Router, public bsModalRef: BsModalRef) {
        this.cancelButtonHeaderText = 'Cancel warranty purchase';
        this.cancelButtonContentText = 'purchase of the warranty?';
        this.cancelURL = '/widdetails';
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    showModal(): void {
        this.isModalShown = true;
    }

    hideModal(): void {
        this.autoShownModal.hide();
    }
    hideModalAndNavigate(cancelUrl) {
        // this.autoShownModal.hide();
        this.router.navigate([cancelUrl]);
    }
    onHidden(): void {
        this.autoShownModal.hide();
        this.isModalShown = false;
    }
}
