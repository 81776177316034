import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../app-config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './detailFooter.component.html',
    styleUrls: ['./detailFooter.component.scss']
})
export class DetailsFooterComponent implements OnInit {
    footerLogoPath: string;
    constructor(private environment: AppConfigService) {
        this.footerLogoPath = environment.config.footerLogo;
    }

    ngOnInit() {
    }

}
