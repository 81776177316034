import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';
import { WorkflowService } from '../warrantyInfo/workflow/workflow.service';
import { STEPS } from '../warrantyInfo/workflow/workflow.model';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    env: any;
    showPasswordLinkMessage: boolean;
    constructor(private formBuilder: FormBuilder, private router: Router,
        private workflowService: WorkflowService,
        private environment: AppConfigService) {
        this.env = environment.config;
        this.showPasswordLinkMessage = false;
    }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            emailId: ['', [Validators.required, Validators.email,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
        });
    }
    // get f() {
    //     return this.forgotPasswordForm.controls;
    // }

    // get emailId() {
    //     return this.forgotPasswordForm.get('emailId');
    // }
    onSubmit() {
        this.showPasswordLinkMessage = true;
        const flowData = sessionStorage.getItem('flow');
        if (flowData === 'WarrantyPurchase') {
            this.router.navigate(['/warranty/checkout']);
            this.workflowService.validateStep(STEPS.verify);
        } else if (flowData === 'WebMonitoringPurchase') {
            this.router.navigate(['/wm/wmCheckout']);
        } else if (flowData === 'LandingPageSignUp') {
            this.router.navigate(['/existingWarranty']);
        }
    }

}
