import { Injectable } from '@angular/core';
import {
    HttpClient
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable()
export class PassWIDInfoService {
    private domainName = new BehaviorSubject('');
    selDomain$ = this.domainName.asObservable();
    private urlType = new BehaviorSubject('');
    selUrlType$ = this.urlType.asObservable();
    private repurchaseDomainName = new BehaviorSubject('');
    repurchaseDomain = this.repurchaseDomainName.asObservable();
    private payoutInfo = new BehaviorSubject('');
    selPayInfo$ = this.payoutInfo.asObservable();

    constructor(private http: HttpClient) { }
    setDomainName(dmnName: any) {
        this.domainName.next(dmnName);
    }
    setUrlType(urlTp: any) {
        this.urlType.next(urlTp);
    }
    setPayoutInfo(pay: any) {
        this.payoutInfo.next(pay);
    }
    setRepurchaseDomain(domainName: any) {
        this.repurchaseDomainName.next(domainName);
    }
}
