import { NgModule, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WarrantyInfoRoutingModule } from './pages/warrantyInfo/warrantyInfo-routing.module';
import { WarrantyInfoParentComponent } from './pages/warrantyInfo/warrantyInfoParent/warrantyInfoParent.component';
import { WarrantyInfoNavbarComponent } from './pages/warrantyInfo/warrantyInfoNavbar/warrantyInfoNavbar.component';
import { WarrantyInfoCoverageComponent } from './pages/warrantyInfo/warrantyInfoCoverage/warrantyInfoCoverage.component';
import { WarrantyInfoDomainComponent } from './pages/warrantyInfo/warrantyInfoDomain/warrantyInfoDomain.component';
import { WarrantyInfoPurchaseComponent } from './pages/warrantyInfo/warrantyInfoPurchase/warrantyInfoPurchase.component';
import { WarrantyInfoWebpageComponent } from './pages/warrantyInfo/warrantyInfoWebpage/warrantyInfoWebpage.component';
import { WorkflowService } from './pages/warrantyInfo/workflow/workflow.service';
import { ShortHeaderComponent } from './pages/common/shortHeader/shortHeader.component';
import { WarrantyInfoCommonErrorComponent } from './pages/warrantyInfo/errorPages/common/websiteInfoErrorContent/warrantyInfoCommonError.component';
import { ErrorTextComponent } from './pages/warrantyInfo/errorPages/common/errorTextComponent/errorText.component';
import { StepperStepsCompletedService } from './services/stepperStepsCompleted.service';
import { ReferenceSiteListComponent } from './pages/warrantyInfo/referenceSiteList/referenceSiteList.component';
import { WarrantySummaryComponent } from './pages/warrantyInfo/warrantySummary/warrantySummary.component';
import { ProfileHeaderComponent } from './pages/common/profileHeader/profileHeader.component';
import { ProcessCancelModalComponent } from './pages/common/processCancelModal/processCancelModal.component';
import { WarrantycheckoutComponent } from './pages/warrantyInfo/warrantycheckout/warrantycheckout.component';
import { WarrantyPurchaseErrorComponent } from './pages/warrantyInfo/errorPages/warrantyPurchaseError/warrantyPurchaseError.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { Ng5SliderModule } from 'ng5-slider';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MainComponent } from './pages/main/main.component';
import { LandingComponent } from './pages/landing/landing.component';
import { HeaderNavigationBarComponent } from './pages/common/header-navigation-bar/header-navigation-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedBootstrapModule } from './pages/shared-bootstrap.module';
import { DetailsFooterComponent } from './pages/common/detailFooter/detailFooter.component';
import { AppServices } from './app.service';
import { MarketWarranty } from './services/marketWarranty.service';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { ShortFooterComponent } from './pages/common/shortFooter/shortFooter.component';
import { HeaderRiskDropdown } from './services/headerRiskDropdown.service';
import { AllRiskDetailService } from './services/allRiskDetails.service';
import { AnalysedWebsiteService } from './services/getAnalysedWebsite.service';
import { WebsiteWarrantyService } from './services/warrantyForWebsite.service';
import { AppConfigService } from './app-config.service';
import { WarrantySummaryService } from './services/warrantySummary.service';
import { WarrantyDetailTabService } from './services/warrantyDetailTab.service';
import { WarrantySummaryReferenceSiteService } from './services/warrantySummaryReferenceSite.service';
import { WarrantyCoverageCalc } from './services/WarrantyCoverageCalc';
import { SignUpComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { WarrantyPaymentSuccessService } from './services/warrantyPaymentSuccess.service';
import { WebpageUrlNotFoundComponent } from './pages/common/webpageNotFound/webpageUrlNotFound.component';
import { ExistingWarrantyComponent } from './pages/existingWarranty/existingWarranty.component';
import { AllWarrantiesIntroComponent } from './pages/common/allWarrantiesShortIntro/allWarrantiesShortIntro.component';
import { UtilityService } from '././services/utility.service';
import { WarrantyHistoryDetailService } from './services/warrantyHistoryDetail.service';
import { WarrantySettingsService } from './services/warrantySettings.service';
import { ExistingWarrantySummary } from './services/existingWarrantyInfo.service';
import { SelectWarrantyComponent } from './pages/selectWarranty/selectWarranty.component';
import { WarrantyDetailMainComponent } from './pages/warrantyDetails/warranty-detail-main/warranty-detail-main.component';
import { WarrentyDetailFooterComponent } from './pages/warrantyDetails/warrenty-detail-footer/warrenty-detail-footer.component';
import { WarrantyDetailComponent } from './pages/warrantyDetails/warranty-detail/warranty-detail.component';
import { WarrantyActivityComponent } from './pages/warrantyDetails/warranty-activity/warranty-activity.component';
import { WarrantyHistoryComponent } from './pages/warrantyDetails/warranty-history/warranty-history.component';
import { WarrantySettingComponent } from './pages/warrantyDetails/warranty-setting/warranty-setting.component';
import { UploadModalComponent } from './pages/common/upload-modal/upload-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ManageProfileService } from './services/manageProfile.service';
import { ManageProfileComponent } from './pages/manageProfile/manageProfile.component';
import { ProfileNotificationsComponent } from './pages/profileNotifications/profileNotifications.component';
import { ProfileNotificationService } from './services/profileNotification.service';
import { WarrantySettingDetailComponent } from './pages/warrantyDetails/warranty-setting-detail/warranty-setting-detail.component';
import { UserAdditionalInfoComponent } from './pages/common/userAdditionalInformation/userAdditionalInformation.component';
import { EmailVerificationComponent } from './pages/emailVerification/emailVerify.component';
import { FormatNumberDirective } from './pages/common/userAdditionalInformation/numeric.directive';
import { PassWIDInfoService } from './services/passWIDInfo.service';
import { CompanySearchService } from './services/companySearch.service';
import { PassUserInfoService } from './services/passUserInfo.service';
import { WMDetailsComponent } from './pages/websiteMonitoring/WMDetails/WMDetails.component';
import { WMDetailsService } from './services/wmDetails.service';
import { WMInfoRoutingModule } from './pages/websiteMonitoring/WMPurchaseFlow/WMInfo-routing.module';
import { WMWorkflowService } from './pages/websiteMonitoring/WMPurchaseFlow/WMWorkflow/WMWorkflow.service';
import { WMPurchaseComponent } from './pages/websiteMonitoring/WMPurchaseFlow/WMPurchase/WMPurchase.component';
import { WMInfoParentComponent } from './pages/websiteMonitoring/WMPurchaseFlow/WMInfoParent/WMInfoParent.component';
import { WMInfoNavbarComponent } from './pages/websiteMonitoring/WMPurchaseFlow/WMInfoNavbar/WMInfoNavbar.component';
import { WMReviewComponent } from './pages/websiteMonitoring/WMPurchaseFlow/WMReview/wmreview.component';
import { ReviewCheckoutFormComponent } from './pages/websiteMonitoring/WMPurchaseFlow/review-checkout-form/review-checkout-form.component';
import { WMCheckoutSuccessComponent } from './pages/websiteMonitoring/WMPurchaseFlow/wmcheckout-success/wmcheckout-success.component';
import { WMPurchaseService } from './services/wmPurchase.service';
import { WmStepsCompletedService } from './services/wmServiceStepsComplete.service';
import { NumberOnlyDirective } from './directive/number.directive';
import { ManageWarrantyMainComponent } from './pages/manageWarranty/manage-warranty-main/manage-warranty-main.component';
import { ManageSummaryComponent } from './pages/manageWarranty/manage-summary/manage-summary.component';
import { ManageActivityComponent } from './pages/manageWarranty/manage-activity/manage-activity.component';
import { ManagePayoutComponent } from './pages/manageWarranty/manage-payout/manage-payout.component';
import { ManageBillingComponent } from './pages/manageWarranty/manage-billing/manage-billing.component';
import { ManageServiceComponent } from './pages/manageService/manageService.component';
import { ManageDetailsService } from './services/manageServices.service';
import { WeatherLocationFormService } from './services/weatherLocationForm.service';
import { ManageProductFlow } from './services/manageProductFlow.service';
import { WeatherDetail } from './services/weatherDetail.service';
import { MonitorMainComponent } from './pages/manageMonitoring/monitor-main/monitor-main.component';
import { MonitorActivityComponent } from './pages/manageMonitoring/monitor-activity/monitor-activity.component';
import { MonitorBillingComponent } from './pages/manageMonitoring/monitor-billing/monitor-billing.component';
import { MonitorManageComponent } from './pages/manageMonitoring/monitor-manage/monitor-manage.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermConditionsComponent } from './pages/term-conditions/term-conditions.component';
import { HelpComponent } from './pages/help/help.component';
import { ForgotPasswordComponent } from './pages/forgotPassword/forgot-password.component';
import { CreateNewPasswordComponent } from './pages/createNewPassword/create-new-password.component';
import { PaymentFailureComponent } from './pages/warrantyInfo/paymentFailure/payment-failure.component';
import { ManageEndorsementMainComponent } from './pages/manageEndorsement/manage-endorsement-main/manage-endorsement-main.component';
import { EndorseSummaryComponent } from './pages/manageEndorsement/endorse-summary/endorse-summary.component';
import { EndorsePayoutComponent } from './pages/manageEndorsement/endorse-payout/endorse-payout.component';
import { EndorseBillingComponent } from './pages/manageEndorsement/endorse-billing/endorse-billing.component';
import { WeatherDetailComponent } from './pages/weatherDetail/weather-detail.component';
const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        WarrantyInfoRoutingModule,
        Ng5SliderModule,
        SharedBootstrapModule,
        NgbModule.forRoot(),
        NgxPageScrollModule,
        FileUploadModule,
        GooglePlaceModule,
        WMInfoRoutingModule
    ],
    exports: [
        ProcessCancelModalComponent,
        WarrantyInfoParentComponent,
        WarrantyInfoNavbarComponent,
        WarrantyInfoCoverageComponent,
        WarrantyInfoDomainComponent,
        WarrantyInfoPurchaseComponent,
        WarrantyInfoWebpageComponent,
        ShortHeaderComponent,
        WarrantyInfoCommonErrorComponent,
        ErrorTextComponent,
        ReferenceSiteListComponent,
        WarrantySummaryComponent,
        ProfileHeaderComponent,
        WarrantycheckoutComponent,
        WarrantyPurchaseErrorComponent,
        MainComponent,
        LandingComponent,
        DetailsFooterComponent,
        HeaderNavigationBarComponent,
        MarketingComponent,
        ShortFooterComponent,
        SignUpComponent,
        LoginComponent,
        WebpageUrlNotFoundComponent,
        ExistingWarrantyComponent,
        ManageProfileComponent,
        AllWarrantiesIntroComponent,
        SelectWarrantyComponent,
        WarrantyDetailMainComponent,
        WarrentyDetailFooterComponent,
        WarrantyDetailComponent,
        WarrantyActivityComponent,
        WarrantyHistoryComponent,
        WarrantySettingComponent,
        UploadModalComponent,
        ManageProfileComponent,
        ProfileNotificationsComponent,
        WarrantySettingDetailComponent,
        UserAdditionalInfoComponent,
        EmailVerificationComponent,
        FormatNumberDirective,
        WMDetailsComponent,
        WMPurchaseComponent,
        WMInfoParentComponent,
        WMInfoNavbarComponent,
        WMReviewComponent,
        ReviewCheckoutFormComponent,
        WMCheckoutSuccessComponent,
        NumberOnlyDirective,
        ManageWarrantyMainComponent,
        ManageSummaryComponent,
        ManageActivityComponent,
        ManagePayoutComponent,
        ManageBillingComponent,
        ManageServiceComponent,
        MonitorMainComponent,
        MonitorActivityComponent,
        MonitorBillingComponent,
        MonitorManageComponent,
        ContactUsComponent,
        AboutUsComponent,
        PrivacyPolicyComponent,
        TermConditionsComponent,
        HelpComponent,
        ForgotPasswordComponent,
        CreateNewPasswordComponent,
        PaymentFailureComponent,
        ManageEndorsementMainComponent,
        EndorseSummaryComponent,
        EndorsePayoutComponent,
        EndorseBillingComponent,
        WeatherDetailComponent
    ],
    declarations: [
        ProcessCancelModalComponent,
        WarrantyInfoParentComponent,
        WarrantyInfoNavbarComponent,
        WarrantyInfoCoverageComponent,
        WarrantyInfoDomainComponent,
        WarrantyInfoPurchaseComponent,
        WarrantyInfoWebpageComponent,
        ShortHeaderComponent,
        WarrantyInfoCommonErrorComponent,
        ErrorTextComponent,
        ReferenceSiteListComponent,
        WarrantySummaryComponent,
        ProfileHeaderComponent,
        WarrantycheckoutComponent,
        WarrantyPurchaseErrorComponent,
        MainComponent,
        LandingComponent,
        DetailsFooterComponent,
        HeaderNavigationBarComponent,
        MarketingComponent,
        ShortFooterComponent,
        SignUpComponent,
        LoginComponent,
        WebpageUrlNotFoundComponent,
        ExistingWarrantyComponent,
        ManageProfileComponent,
        AllWarrantiesIntroComponent,
        SelectWarrantyComponent,
        WarrantyDetailMainComponent,
        WarrentyDetailFooterComponent,
        WarrantyDetailComponent,
        WarrantyActivityComponent,
        WarrantyHistoryComponent,
        WarrantySettingComponent,
        UploadModalComponent,
        ManageProfileComponent,
        ProfileNotificationsComponent,
        WarrantySettingDetailComponent,
        UserAdditionalInfoComponent,
        EmailVerificationComponent,
        FormatNumberDirective,
        WMDetailsComponent,
        WMPurchaseComponent,
        WMInfoParentComponent,
        WMInfoNavbarComponent,
        WMReviewComponent,
        ReviewCheckoutFormComponent,
        WMCheckoutSuccessComponent,
        NumberOnlyDirective,
        ManageWarrantyMainComponent,
        ManageSummaryComponent,
        ManageActivityComponent,
        ManagePayoutComponent,
        ManageBillingComponent,
        ManageServiceComponent,
        MonitorMainComponent,
        MonitorActivityComponent,
        MonitorBillingComponent,
        MonitorManageComponent,
        ContactUsComponent,
        AboutUsComponent,
        PrivacyPolicyComponent,
        TermConditionsComponent,
        HelpComponent,
        ForgotPasswordComponent,
        CreateNewPasswordComponent,
        PaymentFailureComponent,
        ManageEndorsementMainComponent,
        EndorseSummaryComponent,
        EndorsePayoutComponent,
        EndorseBillingComponent,
        WeatherDetailComponent
    ],
    entryComponents: [UploadModalComponent, ProcessCancelModalComponent]

})
export class AllComponentsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AllComponentsModule,
            providers: [
                { provide: WorkflowService, useClass: WorkflowService },
                StepperStepsCompletedService,
                AppServices,
                MarketWarranty,
                HeaderRiskDropdown,
                AllRiskDetailService,
                AnalysedWebsiteService,
                WebsiteWarrantyService,
                WarrantySummaryService,
                WarrantySummaryReferenceSiteService,
                WarrantyPaymentSuccessService,
                WarrantyCoverageCalc,
                WarrantyDetailTabService,
                AppConfigService,
                UtilityService,
                WarrantySettingsService,
                ExistingWarrantySummary,
                ManageProfileService,
                WarrantyHistoryDetailService,
                PassWIDInfoService,
                ProfileNotificationService,
                PassUserInfoService,
                CompanySearchService,
                WMDetailsService,
                WMWorkflowService,
                WMPurchaseService,
                WmStepsCompletedService,
                ManageDetailsService,
                WeatherLocationFormService,
                ManageProductFlow,
                WeatherDetail,
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInitializerFn,
                    multi: true,
                    deps: [AppConfigService]
                }
            ]
        };
    }
}
