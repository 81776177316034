import { Component, OnInit, NgModule } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-select-warranty',
    templateUrl: './selectWarranty.component.html',
    styleUrls: ['./selectWarranty.component.scss']
})
export class SelectWarrantyComponent implements OnInit {

    constructor(private util: UtilityService, private router: Router) {

    }

    backHandle() {
        this.router.navigate(['existingWarranty']);
    }
    ngOnInit() {
    }

}
