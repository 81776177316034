import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { Subscription } from 'rxjs';
import { UtilityService } from './../../../services/utility.service';
// import * as moment from 'moment';
@Component({
    selector: 'app-monitor-billing',
    templateUrl: './monitor-billing.component.html',
    styleUrls: ['./monitor-billing.component.scss']
})
export class MonitorBillingComponent implements OnInit {

    private serviceDetailSubscription: Subscription;
    billingHistoryData: any[];
    env: any;
    selectedService: any = {};

    constructor(private environment: AppConfigService, private manageDetails: ManageDetailsService, private util: UtilityService) {
        this.env = environment.config;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
                if (this.selectedService.billingHistory !== undefined) {
                    this.billingHistoryData = this.selectedService.billingHistory;
                }
            });
    }
    ngOnInit() {
    }
    // historyDateConverter(date) {
    //     return `${moment(date).format('MMM D, YYYY')}`;
    // }
    // dateFinder() {
    //     return `${moment().format('MMMM D, YYYY')}`;
    // }
    cancelWarranty() {

    }
    receiptDownload() {

    }
}
