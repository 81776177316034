import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


import { AppConfigService } from '../../../app-config.service';
import { ManageDetailsService } from '../../../services/manageServices.service';
import { PassWIDInfoService } from '../../../services/passWIDInfo.service';

import { PageScrollService } from 'ngx-page-scroll';

@Component({
    selector: 'app-manage-summary',
    templateUrl: './manage-summary.component.html',
    styleUrls: ['./manage-summary.component.scss']
})
export class ManageSummaryComponent implements OnInit, OnDestroy {
    private serviceDetailSubscription: Subscription;
    isCollapsedUserInfo: boolean;
    payoutPendingCheck: boolean;
    showActiveHourstext: boolean;
    selectedService: any = {};
    payoutPendingState: boolean;
    showMassOutageText: boolean;
    env: any;
    //   userInfoInputNumbers = new FormGroup({
    //     EINSNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)
    //     ]
    // ),
    // });

    constructor(private router: Router, private pageScrollService: PageScrollService, private environment: AppConfigService,
        private manageDetails: ManageDetailsService, private passWIDInfoService: PassWIDInfoService) {
        this.isCollapsedUserInfo = true;
        this.payoutPendingCheck = false;
        this.env = environment.config;
        this.showActiveHourstext = false;
        this.showMassOutageText = false;
        this.serviceDetailSubscription = this.manageDetails.selService$.subscribe(
            service => {
                this.selectedService = service;
            });
        this.serviceDetailSubscription = this.manageDetails.payoutPending.subscribe(
            service => {
                this.payoutPendingState = service;
            });
    }
    downloadDocument(doc) {
        window.open(doc, '_blank');
        // console.log('download document');
    }
    payoutApproveConfirm() {
        console.log('payout pending', this.payoutPendingCheck);
        this.manageDetails.payoutPendingStatusInfo(this.payoutPendingCheck);
    }
    repurchaseWarranty(service) {
        this.passWIDInfoService.setDomainName(service.websiteName);
        this.router.navigate(['/warranty/domain']);
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        this.serviceDetailSubscription.unsubscribe();
    }
    openUrl(service) {
        const site = service.website;
        // let site = 'https://' + service.website;
        // if (service.urlType !== undefined && service.urlType !== null) {
        //     if (service.urlType.length === 2) {
        //         site = 'https://' +  service.website;
        //     } else if (service.urlType.length === 1) {
        //         if (service.urlType[0] === 'HTTPS') {
        //             site = 'https://' +  service.website;
        //         }
        //         if (service.urlType[0] === 'HTTP') {
        //             site = 'http://' +  service.website;
        //         }
        //     }
        // }
        window.open(site, '_blank');
    }
}
